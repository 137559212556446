import React from "react";

const DeleteIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M3.99992 12.6667C3.99992 13.4 4.59992 14 5.33325 14H10.6666C11.3999 14 11.9999 13.4 11.9999 12.6667V4.66667H3.99992V12.6667ZM12.6666 2.66667H10.3333L9.66658 2H6.33325L5.66659 2.66667H3.33325V4H12.6666V2.66667Z"
        fill="#0B2342"
      />
    </svg>
  );
};

export default DeleteIcon;
