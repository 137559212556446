import "../../../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Button from "react-bootstrap/Button";
import LargeAlert from "../../LargeAlert.jsx";
import { Redirect } from "react-router-dom";
import MixpanelService from "../../../mixpanel/mixpanelService";

@inject("store")
@observer
class LogoutBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      isError: false,
      redirectToLogin: false,
    };

    this.handleLogoutSubmit = this.handleLogoutSubmit.bind(this);
  }

  async handleLogoutSubmit(e) {
    e.preventDefault();

    this.setState({ isDisabled: true });
    // const payload = {
    //   token: this.props.store.getRefreshToken,
    // };

    try {
      // await axios.post(BASE_URL_AUTH + USERS + LOGOUT, payload);
      this.setState({
        isError: false,
        isDisabled: true,
      });

      localStorage.removeItem("accessToken");
      localStorage.removeItem("user_name");
      localStorage.removeItem("projectName");
      MixpanelService.reset();

      // this.props.store.setUsername("");
      this.props.store.setAccessToken("");
      this.props.store.setRefreshToken("");
      this.props.store.setLoggedIn(false);

      this.props.store.setLogoutModal(false);
      this.setState({ redirectToLogin: true });
    } catch (e) {
      if (e.response === undefined) {
        console.log(e);
      } else if (
        e.response.status === 401 ||
        e.response.status === 403 ||
        e.response.status === 500
      ) {
        this.setState({
          isError: true,
          isDisabled: false,
        });
      } else {
        this.setState({
          isError: true,
          isDisabled: false,
        });
      }
    }
  }

  render() {
    if (this.state.redirectToLogin) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="text-center mt-3">
        <Button
          variant="btn"
          className="login-submit-button"
          disabled={this.state.isDisabled}
          onClick={(e) => {
            this.handleLogoutSubmit(e);
          }}
        >
          Logout
        </Button>
        {this.state.isError && (
          <LargeAlert message="Some ErrorOcurred" variant="danger" />
        )}
      </div>
    );
  }
}

export default LogoutBody;
