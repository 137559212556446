import React from "react";

const RightArrow = ({ width = 24, height = 24, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.43099e-07C15.1826 1.81051e-07 18.2348 1.26428 20.4853 3.51472C22.7357 5.76515 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 1.05146e-07 15.1826 1.43099e-07 12C1.81051e-07 8.8174 1.26428 5.76515 3.51472 3.51472C5.76516 1.26428 8.8174 1.05146e-07 12 1.43099e-07ZM7.5 11.25C7.30109 11.25 7.11032 11.329 6.96967 11.4697C6.82902 11.6103 6.75 11.8011 6.75 12C6.75 12.1989 6.82902 12.3897 6.96967 12.5303C7.11032 12.671 7.30109 12.75 7.5 12.75L14.6895 12.75L11.469 15.969C11.3993 16.0387 11.344 16.1215 11.3062 16.2126C11.2685 16.3037 11.2491 16.4014 11.2491 16.5C11.2491 16.5986 11.2685 16.6963 11.3062 16.7874C11.344 16.8785 11.3993 16.9613 11.469 17.031C11.5387 17.1007 11.6215 17.156 11.7126 17.1938C11.8037 17.2315 11.9014 17.2509 12 17.2509C12.0986 17.2509 12.1963 17.2315 12.2874 17.1938C12.3785 17.156 12.4613 17.1007 12.531 17.031L17.031 12.531C17.1008 12.4613 17.1563 12.3786 17.1941 12.2875C17.2319 12.1963 17.2513 12.0987 17.2513 12C17.2513 11.9013 17.2319 11.8037 17.1941 11.7126C17.1563 11.6214 17.1008 11.5387 17.031 11.469L12.531 6.969C12.3902 6.82817 12.1992 6.74905 12 6.74905C11.8008 6.74905 11.6098 6.82817 11.469 6.969C11.3282 7.10983 11.2491 7.30084 11.2491 7.5C11.2491 7.69916 11.3282 7.89017 11.469 8.031L14.6895 11.25L7.5 11.25Z"
        fill="#EEEEEE"
      />
    </svg>
  );
};

export default RightArrow;
