import React from "react";

const CancelIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M7.99998 1.3335C4.31331 1.3335 1.33331 4.3135 1.33331 8.00016C1.33331 11.6868 4.31331 14.6668 7.99998 14.6668C11.6866 14.6668 14.6666 11.6868 14.6666 8.00016C14.6666 4.3135 11.6866 1.3335 7.99998 1.3335ZM7.99998 13.3335C5.05998 13.3335 2.66665 10.9402 2.66665 8.00016C2.66665 5.06016 5.05998 2.66683 7.99998 2.66683C10.94 2.66683 13.3333 5.06016 13.3333 8.00016C13.3333 10.9402 10.94 13.3335 7.99998 13.3335ZM10.3933 4.66683L7.99998 7.06016L5.60665 4.66683L4.66665 5.60683L7.05998 8.00016L4.66665 10.3935L5.60665 11.3335L7.99998 8.94016L10.3933 11.3335L11.3333 10.3935L8.93998 8.00016L11.3333 5.60683L10.3933 4.66683Z"
        fill="#0B2342"
      />
    </svg>
  );
};

export default CancelIcon;
