import "../../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Dropdown } from "react-bootstrap";
import LogoutIcon from "../SvgComponents/LogoutIcon";
import AvatarIcon from "../SvgComponents/AvatarIcon";
import DownArrow from "../SvgComponents/DownArrow";
import PencilIcon from "../SvgComponents/PencilIcon";
import LogoutModal from "../LoginLogoutModal/LogoutModal/LogoutModal.jsx";
import Button from "react-bootstrap/Button";
import Logo from "../SvgComponents/Logo";
import SaveIcon from "../SvgComponents/SaveIcon";
import CancelIcon from "../SvgComponents/CancelIcon";
import ShareIcon from "../SvgComponents/ShareIcon";
import LoginIcon from "../SvgComponents/LoginIcon";
import SignupIcon from "../SvgComponents/SignupIcon";
import ShareModal from "../ShareModal";
import { RiHome6Fill } from "react-icons/ri";

@inject("store")
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      fileNameTemp: localStorage.getItem("projectName"),
    };

    this.uploadFileName = this.uploadFileName.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveFileShow = this.handleSaveFileShow.bind(this);
    this.handelCloseModal = this.handelCloseModal.bind(this);
  }

  handleLogoutShow() {
    this.props.store.setLogoutModal(true);
  }

  handleClose() {
    this.setState({
      showModal: false,
    });
  }

  showInputModal() {
    this.setState({
      showModal: true,
    });
  }

  handleInputChange(event) {
    this.setState({
      fileNameTemp: event.target.value,
    });
  }

  async handleSaveFileShow(e, text=null) {
    e.preventDefault();

    const currentUrl = window.location.href;
    const hasSlug = currentUrl.includes("/project/");
    const name = localStorage.getItem("projectName");

    if (hasSlug && name === "Untitled Project") {
      this.props.store.setSaveFileModal(true);
    } else {
      await this.props.clickFunc(text);
    }
  }

  async handleSaveFileName(e) {
    e.preventDefault();

    const currentUrl = window.location.href;
    const hasSlug = currentUrl.includes("/project/");
    const name = localStorage.getItem("projectName");

    if (hasSlug) {
      this.props.store.setSaveFileModal(true);
    } else {
      await this.props.clickFunc();
    }
  }

  handelCloseModal() {
    this.props.store.setCloseModal(true);
  }

  async uploadFileName() {
    if (this.state.fileNameTemp !== "") {
      localStorage.setItem("projectName", this.state.fileNameTemp);
      await this.props.clickFunc();
    }
  }

  handleCopyLink() {
    this.props.store.setshowShareModal(true);
  }

  render() {
    const { store, dataLoaded } = this.props;
    console.log(dataLoaded);
    const isDashboardRoute = window.location.pathname;
    const isLoggedIn = this.props.store.getLoggedIn;
    var name = "";
    if (dataLoaded && localStorage.getItem("projectName")) {
      name = localStorage.getItem("projectName");
    }
    const currentPath = window.location.pathname;
    const hasViewKey = currentPath.includes("/view/");
    return (
      <div>
        {isDashboardRoute !== "/dashboard" ? (
          <div className="row align-items-center header">
            <div className="col-4 d-flex align-items-center">
              <Logo width="120" height="45" className="my-2" />
              {isLoggedIn && !hasViewKey && (
                <div className="mx-3">
                  <Button
                    className="share-btn fs-14 py-0 d-flex align-items-center"
                    onClick={(e) => this.handelCloseModal(e)}
                  >
                    <RiHome6Fill style={{ fontSize:16 }} />&nbsp; Dashboard
                  </Button>
                </div>
              )}
            </div>
            {isLoggedIn && name && !hasViewKey && (
              <div className="col-4 text-center">
                <div
                  className="cursor-pointer"
                  onClick={(e) => this.handleSaveFileName(e)}
                >
                  <span className="dotted-line">{name}</span>
                  <PencilIcon className="ml-2" />
                </div>
              </div>
            )}

            {name && hasViewKey && (
              <div className="col-4 text-center">
                <div>
                  <span className="dotted-line">{name}</span>
                </div>
              </div>
            )}

            {isLoggedIn && !hasViewKey && dataLoaded && (
              <div className="col-4 d-flex justify-content-end">
              <div className="mx-3">
                <Button
                  className="share-btn fs-14 py-0"
                  onClick={(e) => this.handleCopyLink(e)}
                >
                  <ShareIcon className="share-icon" /> Share
                </Button>
              </div>
              <div className="">
                <Button
                  className="save-btn fs-14 py-0"
                  onClick={(e) => this.handleSaveFileShow(e)}
                >
                  <SaveIcon className="save-icon" /> Save
                </Button>
              </div>
              <div className="mx-3">
                <Button
                  className="close-btn fs-14 py-0"
                  onClick={() => this.handelCloseModal()}
                >
                  <CancelIcon className="close-icon" /> Close
                </Button>
              </div>
            </div>
            )}

            {!isLoggedIn && !hasViewKey && (
                <div className="col-8 d-flex justify-content-end">
                  <Button
                    className="login-btn fs-14 py-0 mx-3"
                    onClick={(e) => this.handleSaveFileShow(e, 'login')}
                  >
                    <LoginIcon className="login-icon" />&nbsp; Login
                  </Button>
                  <Button
                    className="login-btn fs-14 py-0 mx-2"
                    onClick={(e) => this.handleSaveFileShow(e, 'signup')}
                  >
                   <SignupIcon className="login-icon" />&nbsp; Sign up
                  </Button>
                </div>
              )}
            
          </div>
        ) : (
          <div className="row align-items-center header">
            <div className="col-6">
              <Logo width="120" height="45" className="my-2" />
            </div>
            <div className="col-6 dropdown-container d-flex justify-content-end">
              <Dropdown className="mr-4">
                <Dropdown.Toggle
                  variant="none"
                  className="primary-text"
                  id="dropdown-basic"
                >
                  <AvatarIcon className="mr-2 mb-0" />
                  <span className="fs-14">{store.getUsername}</span>
                  <DownArrow className="ml-2 mb-0" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="drop-down-menu">
                  <Dropdown.Item
                    message="Logout"
                    onClick={() => this.handleLogoutShow()}
                    className="primary-text header-logout"
                  >
                    <LogoutIcon className="mr-2" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
        <LogoutModal />
        <ShareModal />
      </div>
    );
  }
}

export default Header;
