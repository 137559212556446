import React from "react";

const PlusIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_32_2828)">
        <path
          d="M6.99996 13.3337C6.99996 13.5989 7.10532 13.8532 7.29285 14.0408C7.48039 14.2283 7.73474 14.3337 7.99996 14.3337C8.26518 14.3337 8.51953 14.2283 8.70707 14.0408C8.8946 13.8532 8.99996 13.5989 8.99996 13.3337V9.00033H13.3333C13.5985 9.00033 13.8529 8.89497 14.0404 8.70743C14.2279 8.5199 14.3333 8.26554 14.3333 8.00033C14.3333 7.73511 14.2279 7.48076 14.0404 7.29322C13.8529 7.10568 13.5985 7.00033 13.3333 7.00033H8.99996V2.66699C8.99996 2.40178 8.8946 2.14742 8.70707 1.95989C8.51953 1.77235 8.26518 1.66699 7.99996 1.66699C7.73474 1.66699 7.48039 1.77235 7.29285 1.95989C7.10532 2.14742 6.99996 2.40178 6.99996 2.66699V7.00033H2.66663C2.40141 7.00033 2.14706 7.10568 1.95952 7.29322C1.77198 7.48076 1.66663 7.73511 1.66663 8.00033C1.66663 8.26554 1.77198 8.5199 1.95952 8.70743C2.14706 8.89497 2.40141 9.00033 2.66663 9.00033H6.99996V13.3337Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_32_2828">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusIcon;
