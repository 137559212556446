import React from "react";

const MoveIcon = ({ width = 22, height = 22, className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={fill}
      className={className}
    >
      <g clipPath="url(#clip0_11_2500)">
        <path
          d="M17.3514 14.9066L16.3791 13.9343L18.6084 11.705H11.7048V18.6086L13.9341 16.3794L14.9064 17.3516L11.0173 21.2407L7.12817 17.3516L8.10047 16.3794L10.3298 18.6086V11.705H3.39161L5.62091 13.9343L4.64866 14.9066L0.75956 11.0175L4.64862 7.12845L5.62091 8.10071L3.39157 10.33H10.3298V3.39185L8.10047 5.62111L7.12817 4.6489L11.0173 0.759754L14.9064 4.6489L13.9341 5.62111L11.7048 3.39185V10.33H18.6084L16.3791 8.10071L17.3514 7.12845L21.2404 11.0175L17.3514 14.9066Z"
          fill={className}
        />
      </g>
      <defs>
        <clipPath id="clip0_11_2500">
          <rect
            width={width}
            height={height}
            fill={fill}
            transform="matrix(1 0 0 -1 0 22)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoveIcon;
