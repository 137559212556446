import mixpanel from 'mixpanel-browser';
import { MIXPANEL_PROJECT_TOKEN } from '../Constants';


// Initialize Mixpanel with your project's token
mixpanel.init(MIXPANEL_PROJECT_TOKEN, {persistence: 'localStorage'});

const MixpanelService = {
  // Function to identify a user
  identify: (id) => {
    mixpanel.identify(id);
  },

  // Function to reset a user
  reset: () => {
    mixpanel.reset();
  },

  // Function to track events
  trackEvent: (event, properties) => {
    mixpanel.track(event, properties);
  },

};

export default MixpanelService;
