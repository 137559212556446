import "../../../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {
  BASE_URL,
  PLANNAR_ENDPOINT
} from "../../../Constants";
import $ from "jquery";
import CloseIcon from "../../SvgComponents/CloseIcon";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";

@inject("store")
@observer
class SaveModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      isError: false,
      fileName: "",
      fileNameTemp: localStorage.getItem("projectName"),
      fileUri: "",
      stateBlob: {},
      saveFileList: [],
      showModal: false,
      copied: false,
      overwrite: "Save",
      id: "",
    };

    this.handleClose = this.handleClose.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }

  handleClose() {
    this.props.store.setSaveFileModal(false);
    this.setState({
      fileName: "",
      fileUri: "",
      showModal: false,
      isError: false,
      removed: false,
      saved: false,
      id: "",
    });
  }

  handleShare() {
    this.setState({
      copied: true,
      isError: false,
      removed: false,
      saved: false,
    });
  }

  handleClick(id, name, uri, event) {
    this.setState({
      id: id,
      fileName: name,
      fileUri: uri,
      isError: false,
      overwrite: "Update",
    });
    $(".save-file-list-item").css("border-color", "#ffffff");
    event.currentTarget.style.borderColor = "#5a6268";
  }

  handleInputChange(event) {
    $(".save-file-list-item").css("border-color", "#ffffff");
    this.setState({
      fileName: event.target.value,
      fileNameTemp: event.target.value,
      isError: false,
      overwrite: "Save",
    });
  }

  async uploadFile(blob) {
    if (!this.state.isDisabled) {
      const data = new FormData();
      data.append("file", blob);
      let token = this.props.store.getAccessToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const currentPath = window.location.pathname;

      const hasSlug = currentPath.includes("/project/");

      const pathSegments = currentPath.split("/");

      const slug = pathSegments[pathSegments.length - 1];

      const name = localStorage.getItem("projectName");

      var id = slug;

      const image = localStorage.getItem("image_file");

      if (this.state.fileNameTemp === "Untitled Project") {
        data.append("name", name);
      } else {
        data.append("name", this.state.fileNameTemp);
      }
      
      if (image) {
        data.append("photo", image);
      }

      try {
        var res = await axios.post(
          BASE_URL + PLANNAR_ENDPOINT + "/" + id,
          data,
          config
        );
        Swal.fire({
          text: "Poject saved successfully.",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000, // Auto-close after 3 seconds
          customClass: "save-alert",
        });
        localStorage.removeItem("image_file");
        if (
          hasSlug &&
          res.data.name !== "Untitled Project" &&
          slug !== res.data.slug
        ) {
          localStorage.setItem("projectName", res.data.name);
          this.props.history.push("/project/" + res.data.slug);
          window.location.reload();
        }
        if (hasSlug && res.data.name === "Untitled Project") {
          this.props.store.setSaveFileModal(false);
        }
        $("#loader").css("display", "none");
        $("#loader-text").text("LOADING");
        this.setState({
          isDisabled: false,
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({ isDisabled: false, isError: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (
      prevState.stateBlob !== this.props.blob &&
      this.props.blob !== null &&
      this.props.blob !== "" &&
      this.state.isDisabled === false
    ) {
      this.setState({
        stateBlob: this.props.blob,
        isDisabled: true,
        isError: false,
        overwrite: "Save",
      });
      this.uploadFile(this.props.blob);
    }

    if (this.props.store.showSaveFileModal && !prevState.showModal) {
      this.setState({
        showModal: true,
        saved: false,
        overwrite: "Save",
        fileName: "",
        uri: "",
        id: "",
      });
    }
  }

  render() {
    const { store } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showSaveFileModal}
        onHide={() => this.handleClose()}
        id="saveModal"
      >
        <div className="text-right mx-2 mt-2">
          <Button
            variant="none"
            className="save-close-btn"
            onClick={() => this.handleClose()}
          >
            <CloseIcon />
          </Button>
        </div>
        <Modal.Body className="pt-0">
          <div className="">
            <div className="save-file-space">
              <div className="black-text fs-16 fw-700 my-3 ml-3">
                Enter your project name
              </div>
              <input
                type="text"
                name="name"
                value={this.state.fileNameTemp}
                placeholder="Enter your project name"
                className="save-file-input ml-3 pl-3"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="text-center my-3">
              <Button
                variant="btn"
                className="save-button"
                // disabled={this.state.isDisabled}
                onClick={this.props.clickFunc}
              >
                {this.state.overwrite}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(SaveModal);
