import React from "react";

const Object = ({ width = 24, height = 24, className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <g
        transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M62 210 l-52 -30 0 -63 0 -63 55 -27 55 -27 55 27 55 27 0 63 0 63
          -52 30 c-29 16 -55 30 -58 30 -3 0 -29 -14 -58 -30z m104 -11 c43 -27 34 -44
          -11 -22 -33 16 -37 16 -70 0 -45 -21 -54 -5 -12 22 40 26 52 26 93 0z m-21
          -33 c18 -13 18 -14 -3 -25 -17 -9 -27 -9 -44 0 -21 11 -21 12 -3 25 10 8 22
          14 25 14 3 0 15 -6 25 -14z m-97 -48 c2 -29 9 -38 33 -48 18 -8 29 -20 29 -32
          0 -19 -2 -19 -42 4 -40 22 -43 26 -46 72 -2 36 0 47 10 44 7 -3 14 -21 16 -40z
          m170 -5 c-3 -45 -6 -49 -45 -71 -41 -23 -43 -23 -43 -4 0 12 11 24 30 32 25
          10 30 18 30 45 0 17 3 35 7 38 17 17 24 3 21 -40z m-108 -19 c0 -22 -2 -23
          -20 -14 -12 6 -20 21 -20 36 0 22 2 23 20 14 12 -6 20 -21 20 -36z m43 -11
          c-16 -13 -18 -12 -21 8 -3 14 4 28 15 36 16 13 18 12 21 -8 3 -14 -4 -28 -15
          -36z"
        />
      </g>
    </svg>
  );
};

export default Object;
