import React from "react";

const TrashIcon = ({ width = 22, height = 22, className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      className={className}
    >
      <path
        d="M20.2493 5.75066H18.9994V20.1251C18.9994 20.3855 18.9506 20.6296 18.8529 20.8575C18.7553 21.0853 18.6218 21.2839 18.4525 21.4531C18.2833 21.6224 18.0847 21.7559 17.8569 21.8535C17.629 21.9512 17.3849 22 17.1245 22H7.12487C6.86447 22 6.62034 21.9512 6.39248 21.8535C6.16463 21.7559 5.96607 21.6224 5.7968 21.4531C5.62754 21.2839 5.49408 21.0853 5.39643 20.8575C5.29878 20.6296 5.24995 20.3855 5.24995 20.1251V5.75066H4V4.50071H8.9998V3.25076C8.9998 3.07499 9.03235 2.91224 9.09745 2.7625C9.16255 2.61277 9.25044 2.48257 9.36111 2.37189C9.47178 2.26122 9.60524 2.17008 9.76148 2.09847C9.91773 2.02685 10.0805 1.9943 10.2497 2.00081H13.9996C14.1754 2.00081 14.3381 2.03336 14.4879 2.09847C14.6376 2.16357 14.7678 2.25145 14.8785 2.36213C14.9891 2.4728 15.0803 2.60626 15.1519 2.7625C15.2235 2.91875 15.2561 3.0815 15.2495 3.25076V4.50071H20.2493V5.75066ZM10.2497 4.50071H13.9996V3.25076H10.2497V4.50071ZM17.7494 5.75066H6.4999V20.1251C6.4999 20.2943 6.56174 20.4408 6.68544 20.5645C6.80913 20.6882 6.95561 20.7501 7.12487 20.7501H17.1245C17.2937 20.7501 17.4402 20.6882 17.5639 20.5645C17.6876 20.4408 17.7494 20.2943 17.7494 20.1251V5.75066ZM10.2497 18.2502H8.9998V8.25056H10.2497V18.2502ZM12.7496 18.2502H11.4997V8.25056H12.7496V18.2502ZM15.2495 18.2502H13.9996V8.25056H15.2495V18.2502Z"
        fill={fill}
      />
    </svg>
  );
};

export default TrashIcon;
