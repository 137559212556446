import "../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseIcon from "./SvgComponents/CloseIcon";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { VIEW_MODEL } from "../Constants";
import { FaCopy } from "react-icons/fa";

@inject("store")
@observer
class ShareModal extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   fileNameTemp: localStorage.getItem("projectName"),
    //   showModal: false,
    //   copied: false,
    // };

    this.handleClose = this.handleClose.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
  }

  handleClose() {
    this.props.store.setshowShareModal(false);
  }

  handleCopyLink() {
    var url = window.location.origin;
    const isDashboardRoute = window.location.pathname;
    var pathname = window.location.pathname;
    var parts = pathname.split("/");
    var slug;
    if (isDashboardRoute !== "/dashboard") {
      slug = parts[parts.length - 1];
    } else {
      slug = this.props.slug;
    }

    const linkToCopy = url + '/' + VIEW_MODEL + slug;

    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        Swal.fire({
          text: "Link has been copied.",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000, // Auto-close after 3 seconds
          customClass: "copy-alert",
        });
      })
      .catch((error) => {
        console.error("Error copying link:", error);
      });
  }

  render() {
    const { store } = this.props;
    var url = window.location.origin;
    const isDashboardRoute = window.location.pathname;
    var pathname = window.location.pathname;
    var parts = pathname.split("/");
    var slug;
    if (isDashboardRoute !== "/dashboard") {
      slug = parts[parts.length - 1];
    } else {
      slug = this.props.slug;
    }

    var linkToShow = url + '/' + VIEW_MODEL + slug;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showShareModal}
        onHide={() => this.handleClose()}
        id="shareModal"
      >
      <Modal.Header className="p-1 align-items-center">
        <Modal.Title className="fs-20 fw-700 my-auto">Share URL</Modal.Title>
        <div className="text-right mx-2 my-auto">
        <Button
          variant="none"
          className="text-right save-close-btn"
          onClick={() => this.handleClose()}
        >
          <CloseIcon />
        </Button>
      </div>
      </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="">
            <div className="share-box mx-auto my-4 py-2 px-2">
              <div className="cursor-pointer"  onClick={(e) => this.handleCopyLink(e)}>
                { linkToShow }&nbsp; | &nbsp; <span className="fw-700"><FaCopy /> Copy</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(ShareModal);
