import React from "react";

const CircleTickIcon = ({ width = 26, height = 26, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      className={className}
    >
      <path
        d="M15.9999 29.0787C21.891 29.0787 26.6666 24.3363 26.6666 18.4861C26.6666 12.636 21.891 7.89355 15.9999 7.89355C10.1089 7.89355 5.33325 12.636 5.33325 18.4861C5.33325 24.3363 10.1089 29.0787 15.9999 29.0787Z"
        fill="#202020"
        fillOpacity="0.1"
      />
      <path
        d="M11.3333 17.1619L15.1919 20.9937C15.2294 21.0309 15.2803 21.0518 15.3333 21.0518C15.3863 21.0518 15.4371 21.0309 15.4746 20.9937L25.9999 10.5415"
        stroke="#202020"
        strokeWidth="1.2"
      />
      <path
        d="M25.8107 16.561C26.2538 18.787 25.9158 21.0965 24.8532 23.1053C23.7905 25.114 22.0671 26.7007 19.9699 27.6014C17.8727 28.5021 15.5281 28.6624 13.3264 28.0556C11.1247 27.4489 9.19859 26.1116 7.86872 24.2665C6.53885 22.4213 5.8854 20.1795 6.01713 17.9142C6.14885 15.649 7.0578 13.4968 8.5927 11.8159C10.1276 10.135 12.1959 9.02682 14.4533 8.67575C16.7107 8.32468 19.0211 8.75191 21 9.88633"
        stroke="#202020"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CircleTickIcon;
