import React from "react";

const ShareIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512 512"
      className={className}
    >
      <g>
        <path
          d="M225.493 323.087c-9.764 0-18.945-3.805-25.863-10.711-54.632-54.638-54.632-143.539 0-198.174l73.219-73.219C299.272 14.554 334.459 0 371.932 0s72.66 14.554 99.082 40.983c54.632 54.635 54.632 143.536 0 198.171l-48.809 48.812c-6.906 6.909-16.087 10.714-25.863 10.717-9.764 0-18.945-3.805-25.863-10.711-6.906-6.909-10.717-16.096-10.717-25.869 0-9.77 3.81-18.957 10.717-25.866l48.809-48.812c26.113-26.113 26.113-68.599 0-94.71-12.622-12.622-29.447-19.576-47.356-19.576s-34.734 6.954-47.356 19.576l-73.219 73.219c-26.113 26.11-26.113 68.596 0 94.707 14.265 14.262 14.265 37.47 0 51.732-6.919 6.91-16.1 10.714-25.864 10.714z"
          fill="#000000"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M140.068 512c-37.473 0-72.66-14.554-99.082-40.983-54.632-54.635-54.632-143.536 0-198.171l36.616-36.613c6.906-6.906 16.099-10.711 25.863-10.711s18.957 3.807 25.863 10.717c6.906 6.906 10.717 16.093 10.717 25.863 0 9.773-3.81 18.96-10.717 25.869l-36.616 36.61c-26.113 26.107-26.113 68.593 0 94.704 12.622 12.622 29.447 19.576 47.356 19.576s34.734-6.951 47.356-19.576l73.219-73.219c26.113-26.11 26.113-68.596 0-94.707-6.906-6.909-10.717-16.096-10.717-25.866s3.81-18.957 10.717-25.866c6.906-6.909 16.099-10.711 25.863-10.711s18.957 3.801 25.863 10.708c54.632 54.638 54.632 143.539 0 198.174l-73.219 73.219C212.728 497.446 177.541 512 140.068 512z"
          fill="#000000"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default ShareIcon;