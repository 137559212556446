import "../../App.css";
import React, { Component, createRef } from "react";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { inject, observer } from "mobx-react";
import ItemList from "./ItemList";
import { BASE_URL, TOKEN, PRODUCT_CATEGORIES_ENDPOINT, CATEGORY_PRODUCT_ENDPOINT } from "../../Constants";
import axios from "axios";

@inject("store")
@observer
class ItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryId: null,
      categoryName: null,
      products: [],
      currentPage: 1,
      key: "",
      loading: false,
      highlightedIndex: 0,
    };
    this.navRef = createRef();
  }

  async componentDidMount() {
    if (!this.props.store.getHasViewKey) {
      this.setState({ loading: true });
      await this.fetchCatagories(this.state.currentPage);
    }
  }

  async categoryProducts(k) {
    this.setState({ loading: true });
    const clickedIndex = k - 1;

    if (this.state.highlightedIndex === clickedIndex) {
      // Clicked on the already highlighted item, remove the highlight
      this.setState({ highlightedIndex: -1 });
    } else {
      // Clicked on a different item, highlight it
      this.setState({ highlightedIndex: clickedIndex });
    }
    let selectedCategory = this.state.categories[clickedIndex];
    await this.fetchProducts(selectedCategory.id);
    this.setState({
      categoryId: selectedCategory.id,
      categoryName: selectedCategory.name,
    });
    await this.scrollToHighlightedIndex();
  }

  handleNextCatalog = () => {
    if (this.state.highlightedIndex < this.state.categories.length - 1) {
      this.setState(
        (prevState) => ({
          highlightedIndex: prevState.highlightedIndex + 1,
        }),
      );
      this.scrollToHighlightedIndex();
    }
  };

  handlePrevCatalog = () => {
    if (this.state.highlightedIndex > 0) {
      this.setState(
        (prevState) => ({
          highlightedIndex: prevState.highlightedIndex - 1,
        }),
      );
      this.scrollToHighlightedIndex();
    }
  };

  scrollToHighlightedIndex = () => {
    const navItem = this.navRef.current.children[this.state.highlightedIndex];
    if (navItem) {
      navItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  handelClick = (data) => {
    const { store } = this.props;
    this.props.setSelectedCardData(data);

    store.setClickListener(true);
  };

  async fetchCatagories(page, allCategories = []) {
    await axios
      .get(BASE_URL + PRODUCT_CATEGORIES_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          page: page,
        },
      })
      .then((response) => {
        const newData = response.data.data;
        const categories = allCategories.concat(newData);

        if (page < response.data.last_page) {
          // If there are more pages, recursively fetch the next page
          return this.fetchCatagories(page + 1, categories);
        } else {
          // Filter out categories with names containing "-floor/wall", "- wall", or "- floor"
          const filteredCategories = categories.filter(category => 
            !category.name.toLowerCase().includes('grout') &&
            !category.name.includes('-floor/wall') &&
            !category.name.includes('- wall') &&
            !category.name.includes('- floor')
          );
          // Find the index of the category with name "door frame"
          const doorFrameIndex = filteredCategories.findIndex(category => category.name.toLowerCase() === "door frame");
          // If "door frame" category is found, remove it from its current position and place it at the beginning
          if (doorFrameIndex !== -1) {
            const doorFrameCategory = filteredCategories.splice(doorFrameIndex, 1)[0];
            filteredCategories.unshift(doorFrameCategory);
          }

          this.fetchProducts(filteredCategories[0].id);

          this.setState({
            categories: filteredCategories,
            key: filteredCategories[0].id,
            catalogId: filteredCategories[0].id,
            categoryName: filteredCategories[0].name,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        this.setState({
          loading: false
        });
      });
  }

  async fetchProducts (id, page=1, allProducts = []) {
    await axios
      .get(BASE_URL + CATEGORY_PRODUCT_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          category_id: id,
          page: page,
        },
      }).then((response) => {
        const data = response.data.data;
        const updateProducts = allProducts.concat(data);

        if (page < response.data.last_page) {
          // If there are more pages, recursively fetch the next page
          return this.fetchProducts(id, page + 1, updateProducts);
        } else {
          const filteredProducts = updateProducts.filter(
            (product) =>product.arobject && product.arobject.length > 0
          );
          this.setState({
            products: filteredProducts.reverse(),
            loading: false
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }

  render() {
    const { store } = this.props;
    const { categories, products, categoryId, loading, categoryName } = this.state;
    return (
      <div style={{ height: '100%' }}>
        {loading ? (
        <div className="loading-modal center">
          <img src="/spinner.gif" />
          <span>LOADING</span>
        </div>
      ) : (
        <Card className="catagory-card">
        <Card.Header className="p-0">
          <div className="catagory-container">
            <button
              className="arrow-btn"
              onClick={this.handlePrevCatalog}
              disabled={this.state.highlightedIndex === 0}
            >
              <FaAngleLeft />
            </button>
            <Nav
              ref={this.navRef}
              className="catagory-wrapper"
              variant="underline"
              defaultActiveKey={this.state.highlightedIndex + 1}
              onSelect={(k) => {
                this.categoryProducts(k);
              }}
            >
              {categories.map((category, index) => (
                <Nav.Item key={category.id}>
                  <Nav.Link
                    eventKey={index + 1}
                    className={
                      index === this.state.highlightedIndex ? "highlighted" : ""
                    }
                  >
                    {category.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <button
              className="arrow-btn"
              onClick={this.handleNextCatalog}
              disabled={this.state.highlightedIndex === categories.length - 1}
            >
              <FaAngleRight />
            </button>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {products && (
            <ItemList
            category_id={categoryId}
            products={products}
            categoryName={categoryName}
            loggedIn={store.getLoggedIn}
            setSelectedCardData={this.handelClick}
          />
          )}
        </Card.Body>
      </Card>
      )}
      </div>
    );
  }
}

export default ItemModal;
