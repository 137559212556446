import React from "react";

const LinkIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_11_1005)">
        <path
          d="M6.6665 8.66672C6.95281 9.04948 7.31808 9.36618 7.73754 9.59535C8.157 9.82452 8.62084 9.9608 9.0976 9.99495C9.57437 10.0291 10.0529 9.9603 10.5007 9.79325C10.9486 9.62619 11.3552 9.36477 11.6932 9.02672L13.6932 7.02672C14.3004 6.39805 14.6363 5.55604 14.6288 4.68205C14.6212 3.80806 14.2706 2.97202 13.6526 2.354C13.0345 1.73597 12.1985 1.38541 11.3245 1.37781C10.4505 1.37022 9.60851 1.7062 8.97984 2.31339L7.83317 3.45339"
          stroke="#0B2342"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33322 7.33344C9.04692 6.95069 8.68165 6.63399 8.26219 6.40482C7.84273 6.17564 7.37888 6.03937 6.90212 6.00522C6.42536 5.97108 5.94683 6.03986 5.49899 6.20692C5.05115 6.37398 4.64448 6.6354 4.30656 6.97344L2.30656 8.97344C1.69936 9.60212 1.36338 10.4441 1.37098 11.3181C1.37857 12.1921 1.72913 13.0281 2.34716 13.6462C2.96519 14.2642 3.80123 14.6148 4.67522 14.6224C5.54921 14.63 6.39121 14.294 7.01989 13.6868L8.15989 12.5468"
          stroke="#0B2342"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_1005">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkIcon;
