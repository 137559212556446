import "../../App.css";
import React, { Component, createRef } from "react";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { inject, observer } from "mobx-react";
import TextureList from "./TextureList";
import $ from "jquery";
import { BASE_URL, TOKEN, PRODUCT_CATEGORIES_ENDPOINT, CATEGORY_PRODUCT_ENDPOINT } from "../../Constants";
import axios from "axios";

@inject("store")
@observer
class WallTextureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryName: null,
      categoryId: null,
      products: [],
      currentPage: 1,
      key: "",
      textures: [],
      loading: false,
      highlightedIndex: 0,
    };
    this.navRef = createRef();
  }

  async componentDidMount() {
    if (!this.props.store.getHasViewKey) {
      this.setState({ loading: true });
      await this.fetchCatagories(this.state.currentPage);
    }
  }

  async categoryProducts(k) {
    $("#loader").css("display", "flex");
    const clickedIndex = k - 1;
    const elements = document.querySelectorAll(".texture-select-thumbnail");
    elements.forEach((element) => {
      element.classList.remove("texture-active");
    });

    if (this.state.highlightedIndex === clickedIndex) {
      // Clicked on the already highlighted item, remove the highlight
      this.setState({ highlightedIndex: -1 });
    } else {
      // Clicked on a different item, highlight it
      this.setState({ highlightedIndex: clickedIndex });
    }
    let selectedCategory = this.state.categories[k - 1];
    if (selectedCategory.name.toLowerCase() === "paint - wall") {
      $("#texture-context-menu").hide();
      $("#color-context-menu").show();
    } else {
      $("#color-context-menu").hide();
    }
    await this.fetchProducts(selectedCategory.id);
      this.setState({
        catalogId: selectedCategory.categoryId,
        categoryName: selectedCategory.name,
      });
      await this.scrollToHighlightedIndex();
  }

  async fetchCatagories(page, allCategories = []) {
    await axios
      .get(BASE_URL + PRODUCT_CATEGORIES_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          page: page,
        },
      })
      .then((response) => {
        const newData = response.data.data;
        const categories = allCategories.concat(newData);

        if (page < response.data.last_page) {
          // If there are more pages, recursively fetch the next page
          return this.fetchCatagories(page + 1, categories);
        } else {
          // Filter out categories with names containing "-floor/wall", "- wall", or "- floor"
          const filteredCategories = categories.filter(category => 
            category.name.includes('- floor/wall') ||
            category.name.includes('- wall')
          );

        // Find the index of the category with name "Paint"
        const paintWallIndex = filteredCategories.findIndex(category => category.name.toLowerCase() === "paint - wall");
        // If "paint" category is found, remove it from its current position and place it at the beginning
        if (paintWallIndex !== -1) {
          $("#color-context-menu").show();
          const paintWallCategory = filteredCategories.splice(paintWallIndex, 1)[0];
          filteredCategories.unshift(paintWallCategory);
        }

          this.fetchProducts(filteredCategories[0].id);

          this.setState({
            categories: filteredCategories,
            key: filteredCategories[0].id,
            catalogId: filteredCategories[0].id,
            categoryName: filteredCategories[0].name,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }

  async fetchProducts (id, page=1, allProducts = []) {
    await axios
      .get(BASE_URL + CATEGORY_PRODUCT_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          category_id: id,
          page: page,
        },
      }).then((response) => {
        const data = response.data.data;
        const updateProducts = allProducts.concat(data);

        if (page < response.data.last_page) {
          return this.fetchProducts(id, page + 1, updateProducts);
        } else {
          this.setState({
            products: updateProducts.reverse(),
            loading: false
          });
        }
        $("#loader").css("display", "none");
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        $("#loader").css("display", "none");
      });
  }

  handleNextCatalog = () => {
    if (this.state.highlightedIndex < this.state.categories.length - 1) {
      this.setState(
        (prevState) => ({
          highlightedIndex: prevState.highlightedIndex + 1,
        }),
      );
      this.scrollToHighlightedIndex();
    }
  };

  handlePrevCatalog = () => {
    if (this.state.highlightedIndex > 0) {
      this.setState(
        (prevState) => ({
          highlightedIndex: prevState.highlightedIndex - 1,
        }),
      );
      this.scrollToHighlightedIndex();
    }
  };

  scrollToHighlightedIndex = () => {
    const navItem = this.navRef.current.children[this.state.highlightedIndex];
    if (navItem) {
      navItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  // handelClick = (data) => {
  //   const { store } = this.props;
  //   this.props.setSelectedCardData(data);

  //   store.setClickListener(true);
  // };

  render() {
    const { store } = this.props;
    const { products, categoryId, categories, loading, categoryName } = this.state;
    if (categoryName) {
      if (categoryName.toLowerCase() !== "tile - wall" || categoryName.toLowerCase() !== "ceramic - floor/wall") {
        $("#texture-grouting-context-menu").hide();
      }
    }
    return (
      <Card className="texture-card">
        <Card.Header className="p-0">
          <div className="catagory-container">
            <button
              className="arrow-btn"
              onClick={this.handlePrevCatalog}
              disabled={this.state.highlightedIndex === 0}
            >
              <FaAngleLeft />
            </button>
            <Nav
              ref={this.navRef}
              className="catagory-wrapper"
              variant="underline"
              defaultActiveKey={this.state.highlightedIndex + 1}
              onSelect={(k) => {
                this.categoryProducts(k);
              }}
            >
              {categories.map((category, index) => (
                <Nav.Item key={category.id}>
                  <Nav.Link
                    eventKey={index + 1}
                    className={
                      index === this.state.highlightedIndex ? "highlighted" : ""
                    }
                  >
                    {category.name.split("-")[0].trim()}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <button
              className="arrow-btn"
              onClick={this.handleNextCatalog}
              disabled={
                this.state.highlightedIndex === this.state.textures.length - 1
              }
            >
              <FaAngleRight />
            </button>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
        {products &&(
          <TextureList
            category_id={categoryId}
            products={products}
            categoryName={categoryName}
            loggedIn={store.getLoggedIn}
            // setSelectedCardData={this.handelClick}
          />
        )}
        </Card.Body>
      </Card>
    );
  }
}

export default WallTextureModal;
