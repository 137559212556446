import "../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Card, Button } from "react-bootstrap";
import {
  BASE_URL,
  PLANNAR_ENDPOINT,
  VIEW_MODEL
} from "../Constants.js";
import axios from "axios";
import MenuDots from "./SvgComponents/MenuDots";
import OpenIcon from "./SvgComponents/OpenIcon";
import LinkIcon from "./SvgComponents/LinkIcon";
import DeleteIcon from "./SvgComponents/DeleteIcon";
import Modal from "react-bootstrap/Modal";
import PlusIcon from "./SvgComponents/PlusIcon";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import ShareModal from "./ShareModal";

@inject("store")
@observer
class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      projects: [],
      menuStates: {},
      showDeleteModal: false,
      visibleProjects: 8,
      mounted:false,
      slug: ''
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    var loader = document.createElement("div");
    loader.id = "dashboard-loader";
    loader.className = "loading-modal center";
    loader.style.display = "none";
    var loaderImage = document.createElement("img");
    loaderImage.src = "/spinner.gif";
    loader.appendChild(loaderImage);
    var loaderTitle = document.createElement("span");
    loaderTitle.innerHTML = "LOADING";
    loader.appendChild(loaderTitle);
    document.body.appendChild(loader);
    await this.updateSaveFileList();
    this.setState({
      mounted: true,
    });
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    this.setState({
      mounted: false,
    });
    document.removeEventListener("click", this.handleClickOutside);
  }

  async componentDidUpdate(prevProps, prevState, snapShot) {
    if (this.props.store.showLoadFileModal && !prevState.showModal) {
      this.setState({
        id: "",
      });
      await this.updateSaveFileList();
    }
  }

  async updateSaveFileList() {
    let token = this.props.store.getAccessToken;
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      $("#dashboard-loader").css("display", "flex");
      let res = await axios.get(
        BASE_URL + PLANNAR_ENDPOINT,
        config
      );
      // if(this.state.mounted) {
        this.setState({
          projects: res.data,
          id: "",
        });
      // }
      $("#dashboard-loader").css("display", "none");
    } catch (e) {
      console.log(e);
      $("#dashboard-loader").css("display", "none");
    }
  }

  handleLogoutShow() {
    this.props.store.setLogoutModal(true);
  }

  toggleMenu(id) {
    const updatedMenuStates = {};
    updatedMenuStates[id] = !this.state.menuStates[id];
    this.setState({ menuStates: updatedMenuStates });
  }

  handleClickOutside(event) {
    if (!event.target.closest(".dots-menu-container")) {
      this.setState({ menuStates: {} });
    }
  }

  formatDateTimeString(dateTimeString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString("en-US", options);
    const formattedTime = dateTime.toLocaleTimeString("en-US", {hour: "numeric", minute: "numeric", second: "numeric", hour12: true});
    return `${formattedDate} ${formattedTime}`;
  }

  handleCopyLink(item) {
    this.props.store.setshowShareModal(true);
    this.setState({
      slug: item.slug
    })
  }

  handleClose() {
    this.setState({
      id: "",
      showDeleteModal: false,
    });
  }

  deleteBtn(id) {
    this.setState({ showDeleteModal: true, id: id });
  }

  async handleRemove() {
    let token = this.props.store.getAccessToken;
    let id = this.state.id;
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      $("#dashboard-loader").css("display", "flex");
      await axios.delete(
        BASE_URL + PLANNAR_ENDPOINT + "/" + id,
        config
      );
      Swal.fire({
        text: "The project has been removed",
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timer: 3000, // Auto-close after 3 seconds
        customClass: "copy-alert",
      });
      this.setState({
        id: "",
        showDeleteModal: false,
      });
      this.updateSaveFileList();
      $("#dashboard-loader").css("display", "none");
    } catch (e) {
      console.log(e);
      this.setState({
        id: "",
      });
      $("#dashboard-loader").css("display", "none");
    }
  }

  handleOpenProject(slug, name) {
    this.props.history.push("/project/" + slug);
    localStorage.setItem("projectName", name);
  }

  loadMore() {
    $("#dashboard-loader").css("display", "flex");
    this.setState((prevState) => ({
      visibleProjects: prevState.visibleProjects + 8,
    }));
    $("#dashboard-loader").css("display", "none");
  }

  async handleCreateProject() {
    const data = new FormData();
    let token = this.props.store.getAccessToken;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    localStorage.setItem("projectName", "Untitled Project");
    data.append("name", "Untitled Project");
    try {
      var res = await axios.post(
        BASE_URL + PLANNAR_ENDPOINT,
        data,
        config
      );
      this.props.history.push("/project/" + res.data.slug);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { visibleProjects } = this.state;
    var sortedProjects = [];
    if (this.state.projects != []) {
      sortedProjects = this.state.projects.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
    }
    return (
      <div className="project-list-wrapper">
        <div className="d-flex justify-content-between px-5 pt-5 pb-3">
          <div style={{ fontWeight: 700, fontSize: 24 }}>
            My projects
          </div>
          <Button
            className="fs-16 create-project-btn"
            onClick={() => this.handleCreateProject()}
          >
            <PlusIcon className="mr-2" />
            Project
          </Button>
        </div>
        {this.sortedProjects != [] && (
          <div className="row list-container mx-3">
            {sortedProjects.slice(0, visibleProjects).map((item, iterator) => (
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6" key={iterator}>
                <div className="preview-container m-3">
                  <Card>
                    {/* {item.photo.length > 0 ? (
                      <div className="product-card-img-container">
                      <div className="product-card-img d-flex align-items-center justify-content-center text-center">
                        <img className="product-img" src={item.photo[0].fullUrl} alt={item.name} />
                      </div>
                      <button
                        className="open-button"
                        onClick={() =>
                          this.handleOpenProject(item.slug, item.name)
                        }
                      >
                        Open
                      </button>
                    </div>
                    ): ( */}
                      <div className="preview-card-img-container">
                      <div className="preview-card-img d-flex align-items-center justify-content-center text-center">
                        {/* <div className="preview-text fs-20 fw-700">
                          No preview
                        </div> */}
                        <img src="/preview.png" style={{ width: 50 }} />
                      </div>
                      <button
                        className="open-button"
                        onClick={() =>
                          this.handleOpenProject(item.slug, item.name)
                        }
                      >
                        Open
                      </button>
                    </div>
                    {/* )} */}
                    <Card.Body className="px-0">
                      <div className="card-container">
                        <div className="black-text fs-16 fw-700">
                          {item.name}
                        </div>
                        <div
                          className="dots-menu-container"
                          onClick={() => this.toggleMenu(item.id)}
                        >
                          <MenuDots />
                        </div>
                      </div>
                      <div className="black-text fs-12 fw-400">
                        Lasted Edited {this.formatDateTimeString(item.updated_at)}
                      </div>
                    </Card.Body>
                    {this.state.menuStates[item.id] && (
                      <div className="more-menu">
                        <div className="more-menu-caret">
                          <div className="more-menu-caret-outer"></div>
                          <div className="more-menu-caret-inner"></div>
                        </div>
                        <div
                          className="primary-text cursor-pointer mb-2"
                          onClick={() => this.handleOpenProject(item.slug, item.name)}
                        >
                          <OpenIcon /> Open
                        </div>
                        <div
                          className="primary-text cursor-pointer my-2"
                          onClick={() => this.handleCopyLink(item)}
                        >
                          <LinkIcon /> Share
                        </div>
                        <div
                          className="primary-text cursor-pointer"
                          onClick={() => this.deleteBtn(item.slug)}
                        >
                          <DeleteIcon /> Delete
                        </div>
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            ))}
          </div>
        )}
        {visibleProjects < sortedProjects.length && (
          <div className="text-center mb-3">
            <Button
              variant="primary"
              className="load-more-btn mt-2 btn btn-primary"
              onClick={() => this.loadMore()}
            >
              Load More
            </Button>
          </div>
        )}
        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-center"
          centered
          show={this.state.showDeleteModal}
          onHide={() => this.handleClose()}
          id="deleteModal"
        >
          <Modal.Body>
            <div className="modal-plain-text mt-2 mb-4">
              Are you sure you want to delete?
            </div>
            <div className="d-flex justify-content-center">
              <Button
                variant="danger"
                className="delete-button"
                onClick={() => {
                  this.handleRemove();
                }}
              >
                Delete
              </Button> &nbsp; &nbsp; &nbsp;
              <Button
                variant="light"
                className="cancel-button"
                onClick={() => {
                  this.handleClose();
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <ShareModal slug={this.state.slug} />
      </div>
    );
  }
}

export default withRouter(ProjectList);
