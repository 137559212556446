import React from "react";

const UnlockIcon = ({ width = 24, height = 24, className }) => {
  return (
    <svg
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 438.533 438.533"
      className={className}
    >
      <g>
        <path
          d="M375.721 227.259c-5.331-5.331-11.8-7.992-19.417-7.992H146.176v-91.36c0-20.179 7.139-37.402 21.415-51.678 14.277-14.273 31.501-21.411 51.678-21.411 20.175 0 37.402 7.137 51.673 21.411 14.277 14.276 21.416 31.5 21.416 51.678 0 4.947 1.807 9.229 5.42 12.845 3.621 3.617 7.905 5.426 12.847 5.426h18.281c4.945 0 9.227-1.809 12.848-5.426 3.606-3.616 5.42-7.898 5.42-12.845 0-35.216-12.515-65.331-37.541-90.362C284.603 12.513 254.48 0 219.269 0c-35.214 0-65.334 12.513-90.366 37.544-25.028 25.028-37.542 55.146-37.542 90.362v91.36h-9.135c-7.611 0-14.084 2.667-19.414 7.992-5.33 5.325-7.994 11.8-7.994 19.414v164.452c0 7.617 2.665 14.089 7.994 19.417 5.33 5.325 11.803 7.991 19.414 7.991h274.078c7.617 0 14.092-2.666 19.417-7.991 5.325-5.328 7.994-11.8 7.994-19.417V246.673c.004-7.614-2.662-14.082-7.994-19.414z"
          fill="#000000"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default UnlockIcon;
