import "../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Button, Card, Form} from "react-bootstrap";
import {
  validateEmail,
  validatePassword,
  validateUsername,
  validateMobileNumber,
  parseJwt
} from "../Utils/Utils";
import axios from "axios";
import { BASE_URL_AUTH, CUSTOMER_REGISTER, S_LINK, PLANNAR_ENDPOINT, PABBLY_USER_REGISTER } from "../Constants.js";
import SmallAlert from "./SmallAlert.jsx";
import LargeAlert from "./LargeAlert.jsx";
import { withRouter, Link } from "react-router-dom";
import Logo from "./SvgComponents/Logo";
import DarkRightArrow from "./SvgComponents/DarkRightArrow";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import $ from "jquery";
import MixpanelService from "../mixpanel/mixpanelService";

@inject("store")
@observer
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signupUsernameValue: "",
      signupMobileNumberValue: "",
      signupEmailValue: "",
      signupPasswordValue: "",
      signupConfirmPasswordValue: "",
      showPassword: false,

      signupUsernameFormatError: false,
      signuMobileNumberFormatError: false,
      signupEmailFormatError: false,
      signupPasswordFormatError: false,
      signupConfirmPasswordFormatError: false,
      errorMessage: "",

      isDisabled: false,

      isRegistered: false,
      isEmailExists: false,
      isError: false,
    };

    this.handleSignupUsernameChange = this.handleSignupUsernameChange.bind(
      this
    );
    this.handleSignupEmailChange = this.handleSignupEmailChange.bind(this);
    this.handleSignupPasswordChange = this.handleSignupPasswordChange.bind(
      this
    );
    this.handleSignupSubmit = this.handleSignupSubmit.bind(this);
  }

  componentDidMount() {
    $("#loader").css("display", "none");
    const isLoggedIn = localStorage.getItem("accessToken");
    if (isLoggedIn){
      this.props.history.push("/dashboard");
    }
  }

  handleSignupUsernameChange(e) {
    if (!validateUsername(e.target.value)) {
      this.setState({ signupUsernameFormatError: true });
    } else {
      this.setState({ signupUsernameFormatError: false });
    }
    this.setState({ signupUsernameValue: e.target.value });
  }

  handleSignupMobileNumberChange(e) {
    if (!validateMobileNumber(e.target.value)) {
      this.setState({ signuMobileNumberFormatError: true });
    } else {
      this.setState({ signuMobileNumberFormatError: false });
    }
    this.setState({ signupMobileNumberValue: e.target.value });
  }

  handleSignupEmailChange(e) {
    if (!validateEmail(e.target.value)) {
      this.setState({ signupEmailFormatError: true });
    } else {
      this.setState({ signupEmailFormatError: false });
    }
    this.setState({ signupEmailValue: e.target.value });
  }

  handleSignupPasswordChange(e) {
    if (!validatePassword(e.target.value)) {
      this.setState({ signupPasswordFormatError: true });
    } else {
      this.setState({ signupPasswordFormatError: false });
    }
    this.setState({ signupPasswordValue: e.target.value });
  }

  handleSignupConfirmPasswordChange(e) {
    if (this.state.signupPasswordValue !== e.target.value) {
      this.setState({ signupConfirmPasswordFormatError: true });
    } else {
      this.setState({ signupConfirmPasswordFormatError: false });
    }
    this.setState({ signupConfirmPasswordValue: e.target.value });
  }

  async handleSignupSubmit(e) {
    e.preventDefault();

    if (
      !this.state.signupUsernameFormatError &&
      !this.state.signuMobileNumberFormatError &&
      !this.state.signupEmailFormatError &&
      !this.state.signupPasswordFormatError &&
      !this.state.signupConfirmPasswordFormatError &&
      this.state.signupUsernameValue !== "" &&
      this.state.signupMobileNumberValue !== "" &&
      this.state.signupEmailValue !== "" &&
      this.state.signupPasswordValue !== "" &&
      this.state.signupConfirmPasswordValue !== ""
    ) {
      this.setState({ isDisabled: true });
      const user = {
        name: this.state.signupUsernameValue,
        mobile: this.state.signupMobileNumberValue,
        email: this.state.signupEmailValue,
        password: this.state.signupPasswordValue,
        cpassword: this.state.signupConfirmPasswordValue,
        slink: S_LINK
      };
      const data = {
        name: this.state.signupUsernameValue,
        mobile: this.state.signupMobileNumberValue,
        email: this.state.signupEmailValue,
      };
      let res;
      let plannar_data;
      let pabblyRes;
      try {
        res = await axios.post(BASE_URL_AUTH + CUSTOMER_REGISTER, user);
          if(res.data.msg === "success") {
            pabblyRes = axios.post(PABBLY_USER_REGISTER, data);
            MixpanelService.identify(res.data.uuid);
            MixpanelService.trackEvent("New User Data", {
              'Name': this.state.signupUsernameValue,
              'Mobile': this.state.signupMobileNumberValue,
              'Email': this.state.signupEmailValue,
            });
          }
          const loggedInUser = parseJwt(res.data.accessToken);
          const tokenExpiration = loggedInUser.exp * 1000;

          if (res.data.accessToken) {
            localStorage.setItem("accessToken", res.data.accessToken);
            this.props.store.setAccessToken(res.data.accessToken);
            this.props.store.setLoggedIn(true);
          }
          if (res.data.user_name) {
            localStorage.setItem("user_name", res.data.user_name);
            this.props.store.setUsername(res.data.user_name);
          }

          const tokenExpirationTime = tokenExpiration - Date.now();
          setTimeout(() => {
            localStorage.removeItem("accessToken");
            this.props.store.setLoggedIn(false);
            this.props.store.setAccessToken("");
          }, tokenExpirationTime);

          if(res.data.msg === "success") {
            plannar_data = localStorage.getItem('plannar_data');

            if (plannar_data) {
              const data = new FormData();
              data.append("file", plannar_data);
              let token = this.props.store.getAccessToken;
              let config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };
              localStorage.setItem("projectName", "Untitled Project");
              const name = "Untitled Project";
              data.append("name", name);
              try {
                var login_res = await axios.post(
                  BASE_URL_AUTH + PLANNAR_ENDPOINT,
                  data,
                  config
                );
                localStorage.removeItem('plannar_data');
                localStorage.removeItem("image_file");
                this.props.history.push("/project/" + login_res.data.slug);
              } catch (e) {
                console.log(e);
              }
            } else {
              this.props.history.push("/dashboard");
            }
          }

        // this.setState({
        //   isError: false,
        //   isEmailExists: false,
        //   isDisabled: true,
        //   isRegistered: true,
        // });
      } catch (e) {
        if (res.data.status === "error" || e.response.status === 403 ) {
          this.setState({
            isError: false,
            isEmailExists: true,
            isDisabled: false,
          });
        } else if (e.response === undefined) {
          this.setState({
            isError: true,
            isEmailExists: false,
            isDisabled: true,
          });
        }  else {
          this.setState({
            isError: true,
            isEmailExists: false,
            isDisabled: false,
          });
        }
      }
    }
  }

  render() {
    return (
      <div className="center-container">
        <Card className="login-card w-40 px-3 my-auto">
          <div className="text-center">
            <Logo width="150" height="55" />
          </div>
          <div className="text-center mb-1">
            <div className="fs-14">A product of MirrAR Innovation Technologies Pvt Ltd</div>
          </div>
          <Card.Body className="py-3">
            <h5 className="text-center fs-24 primary-text fw-700">
              Sign Up
            </h5>

            <Form
              className="signup-form"
              onSubmit={(e) => {
                this.handleSignupSubmit(e);
              }}
            >

              {this.state.signupUsernameFormatError && this.state.signupUsernameValue.length > 0 &&  (
                <SmallAlert message="invalid Email" variant="danger" />
              )}

              <Form.Group controlId="name">
                <div className="login-form-input-space">

                  <Form.Label className="primary-text fs-14 fw-600 mb-1">Name</Form.Label>
                  <Form.Control
                    placeholder="Enter your Name"
                    variant="secondary"
                    size="sm"
                    type="text"
                    value={this.state.signupUsernameValue}
                    onChange={(e) => {
                      this.handleSignupUsernameChange(e);
                    }}
                    className="signup-form-input w-100"
                    required
                  />
                </div>
              </Form.Group>

              {this.state.signuMobileNumberFormatError && this.state.signupMobileNumberValue.length > 0 && (
                <SmallAlert message="invalid Mobile Number" variant="danger" />
              )}

              <Form.Group controlId="mobile">
                <div className="login-form-input-space mt-1">

                  <Form.Label className="primary-text fs-14 fw-600 mb-1">Mobile</Form.Label>
                  <Form.Control
                    placeholder="Enter your Mobile Number"
                    variant="secondary"
                    size="sm"
                    type="number"
                    value={this.state.signupMobileNumberValue}
                    onChange={(e) => {
                      this.handleSignupMobileNumberChange(e);
                    }}
                    className="signup-form-input w-100"
                    required
                  />
                </div>
              </Form.Group>

              {this.state.signupEmailFormatError && this.state.signupEmailValue.length > 0 && (
                <SmallAlert message="invalid Email" variant="danger" />
              )}

              <Form.Group controlId="email">
                <div className="login-form-input-space mt-1">

                  <Form.Label className="primary-text fs-14 fw-600 mb-1">Email ID</Form.Label>
                  <Form.Control
                    placeholder="Enter your Email"
                    variant="secondary"
                    size="sm"
                    type="email"
                    value={this.state.signupEmailValue}
                    onChange={(e) => {
                      this.handleSignupEmailChange(e);
                    }}
                    className="signup-form-input w-100"
                    required
                  />
                </div>
              </Form.Group>

              {this.state.signupPasswordFormatError && this.state.signupPasswordValue.length > 0 && (
                <SmallAlert message="min. 8 characters" variant="danger" />
              )}

              <Form.Group controlId="password">
                <div className="login-form-input-space mt-1">
                  <Form.Label className="primary-text fs-14 fw-600 mb-1">
                    Password
                  </Form.Label>
                  <div className="password-input-container">
                    <Form.Control
                      placeholder="Enter your Password"
                      variant="secondary"
                      size="sm"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.signupPasswordValue}
                      onChange={(e) => {
                        this.handleSignupPasswordChange(e);
                      }}
                      className="signup-form-input w-100"
                      required
                    />
                    <div
                      className="password-toggle-icon m-2"
                      onClick={() =>
                        this.setState((prevState) => ({
                          showPassword: !prevState.showPassword,
                        }))
                      }
                    >
                      {this.state.showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </Form.Group>

              {this.state.signupConfirmPasswordFormatError && this.state.signupConfirmPasswordValue.length > 0 && (
                <SmallAlert message="Password does not match" variant="danger" />
              )}

              <Form.Group controlId="confirm_password">
                <div className="login-form-input-space mt-1">
                  
                  <Form.Label className="primary-text fs-14 fw-600 mb-1">Confirm Password</Form.Label>

                  <Form.Control
                    placeholder="Enter your Password"
                    variant="secondary"
                    size="sm"
                    type="password"
                    value={this.state.signupConfirmPasswordValue}
                    onChange={(e) => {
                      this.handleSignupConfirmPasswordChange(e);
                    }}
                    className="signup-form-input w-100"
                    required
                  />
                </div>
              </Form.Group>

              <Button
                variant="btn"
                className="signup-submit-button fs-14 fw-700 mx-auto mt-2"
                type="submit"
                disabled={this.state.isDisabled}
              >
                <DarkRightArrow width={18} height={18} className="right-arrow mr-2" />
                Sign Up
              </Button>
            </Form>

            {this.state.isRegistered && (
              <LargeAlert message="Successfully Registered" variant="success" />
            )}
            {this.state.isError && (
              <LargeAlert message="Some ErrorOcurred" variant="danger" />
            )}
            {this.state.isEmailExists && (
              <LargeAlert message="Email already registered" variant="danger" />
            )}

            <div className="text-center primary-text fs-14 fw-600 mt-2">
              Already registered? <Link to="/login" className="signin-text">Login</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withRouter(Signup);