import "../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Button, Card, Form } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { validateEmail, parseJwt } from "../Utils/Utils";
import axios from "axios";
import { BASE_URL_AUTH, CUSTOMER_LOGIN, S_LINK, PLANNAR_ENDPOINT } from "../Constants.js";
import SmallAlert from "./SmallAlert.jsx";
import LargeAlert from "./LargeAlert.jsx";
import Logo from "./SvgComponents/Logo";
import RightArrow from "./SvgComponents/RightArrow";
import { withRouter, Link } from "react-router-dom";
import $ from "jquery";
import MixpanelService from "../mixpanel/mixpanelService";

@inject("store")
@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginEmailValue: "",
      loginPasswordValue: "",
      loginEmailFormatError: false,
      showPassword: false,
      isEmailExists: true,
      isPasswordCorrect: true,
      isError: false,
    };

    this.handleLoginEmailChange = this.handleLoginEmailChange.bind(this);
    this.handleLoginPasswordChange = this.handleLoginPasswordChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
  }

  componentDidMount() {
    $("#loader").css("display", "none");
    const isLoggedIn = localStorage.getItem("accessToken");
    if (isLoggedIn){
      this.props.history.push("/dashboard");
    }
  }

  handleLoginEmailChange(e) {
    if (!validateEmail(e.target.value)) {
      this.setState({ loginEmailFormatError: true });
    } else {
      this.setState({ loginEmailFormatError: false });
    }
    this.setState({ loginEmailValue: e.target.value });
  }

  handleLoginPasswordChange(e) {
    this.setState({ loginPasswordValue: e.target.value });
  }

  async handleLoginSubmit(e) {
    e.preventDefault();
    if (
      !this.state.loginEmailFormatError &&
      this.state.loginEmailValue !== "" &&
      this.state.loginPasswordValue !== ""
    ) {
      const user = {
        email: this.state.loginEmailValue,
        password: this.state.loginPasswordValue,
        slink: S_LINK,
      };

      try {
        let res = await axios.post(BASE_URL_AUTH + CUSTOMER_LOGIN, user);
        this.setState({
          isError: false,
          isEmailExists: true,
          isPasswordCorrect: true,
        });

        if(res.data.msg === "success") {
          MixpanelService.identify(res.data.uuid);
        }

        const loggedInUser = parseJwt(res.data.accessToken);
        const tokenExpiration = loggedInUser.exp * 1000;

        if (res.data.accessToken) {
          localStorage.setItem("accessToken", res.data.accessToken);
          this.props.store.setAccessToken(res.data.accessToken);
          this.props.store.setLoggedIn(true);
        }
        if (res.data.user_name) {
          localStorage.setItem("user_name", res.data.user_name);
          this.props.store.setUsername(res.data.user_name);
        }

        const tokenExpirationTime = tokenExpiration - Date.now();
        setTimeout(() => {
          localStorage.removeItem("accessToken");
          this.props.store.setLoggedIn(false);
          this.props.store.setAccessToken("");
        }, tokenExpirationTime);

        if(res.data.msg === "success") {

          let plannar_data = localStorage.getItem('plannar_data');

          if (plannar_data) {
            const data = new FormData();
            data.append("file", plannar_data);
            let token = this.props.store.getAccessToken;
            let config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            localStorage.setItem("projectName", "Untitled Project");
            const name = "Untitled Project";
            data.append("name", name);
            try {
              var login_res = await axios.post(
                BASE_URL_AUTH + PLANNAR_ENDPOINT,
                data,
                config
              );
              localStorage.removeItem('plannar_data');
              localStorage.removeItem("image_file");
              this.props.history.push("/project/" + login_res.data.slug);
            } catch (e) {
              console.log(e);
            }
          } else {
            this.props.history.push("/dashboard");
          }
        }

        this.props.store.setLoginModal(false);
      } catch (e) {
        if (e.response === undefined) {
          console.log(e);
        } else if (e.response.status === 400) {
          this.setState({
            isError: false,
            isEmailExists: false,
            isPasswordCorrect: true,
          });
        } else if (e.response.status === 401) {
          this.setState({
            isError: false,
            isEmailExists: true,
            isPasswordCorrect: false,
          });
        } else if (e.response.status === 422) {
          this.setState({
            isError: false,
            isEmailExists: true,
            isPasswordCorrect: false,
          });
        } else {
          this.setState({
            isError: true,
            isEmailExists: true,
            isPasswordCorrect: true,
          });
        }
      }
    }
  }

  render() {
    return (
      <div className="center-container">
        <Card className="login-card w-40 p-3 my-auto">
          <div className="text-center mb-1">
            <Logo width="150" height="55" />
          </div>
          <div className="text-center mb-3">
            <div className="fs-14">A product of MirrAR Innovation Technologies Pvt Ltd</div>
          </div>
          <Card.Body>
            <h5 className="text-center m-3 fs-32 primary-text fw-700">
              Log In
            </h5>

            <Form
              className="login-form"
              onSubmit={(e) => {
                this.handleLoginSubmit(e);
              }}
            >
              {this.state.loginEmailFormatError && (
                <SmallAlert message="invalid Email" variant="danger" />
              )}
              <Form.Group className="mx-3" controlId="email">
                <div className="login-form-input-space">
                  <Form.Label className="primary-text fs-16 fw-600 mb-3">
                    Email ID
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter your Email"
                    variant="secondary"
                    type="email"
                    value={this.state.loginEmailValue}
                    onChange={(e) => {
                      this.handleLoginEmailChange(e);
                    }}
                    className="login-form-input w-100"
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group className="mx-3" controlId="password">
                <div className="login-form-input-space mt-2">
                  <Form.Label className="primary-text fs-16 fw-600 mb-3">
                    Password
                  </Form.Label>
                  <div className="password-input-container">
                    <Form.Control
                      placeholder="Enter your Password"
                      variant="secondary"
                      size="sm"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.loginPasswordValue}
                      onChange={(e) => {
                        this.handleLoginPasswordChange(e);
                      }}
                      className="login-form-input w-100"
                      required
                    />
                    <div
                      className="password-toggle-icon m-2"
                      onClick={() =>
                        this.setState((prevState) => ({
                          showPassword: !prevState.showPassword,
                        }))
                      }
                    >
                      {this.state.showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </Form.Group>

              <Button
                variant="btn"
                className="login-submit-button fs-16 fw-700 mx-auto mt-4"
                type="submit"
              >
                <RightArrow width={18} height={18} className="right-arrow mr-2" />
                Login
              </Button>
            </Form>

            {!this.state.isEmailExists && (
              <LargeAlert message="Email is not registered" variant="danger" />
            )}
            {this.state.isError && (
              <LargeAlert message="Some Error Occurred" variant="danger" />
            )}
            {!this.state.isPasswordCorrect && (
              <LargeAlert message="Invalid Credentials" variant="danger" />
            )}

            <div className="text-center primary-text fs-14 fw-600 mt-2">
              Don’t have account? <Link to="/signup" className="signin-text">Sign Up</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withRouter(Login);
