import React from "react";

const AvatarIcon = ({ width = 24, height = 24, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <circle cx="12" cy="11" r="11" fill="#384350" />
      <path
        d="M12.1428 10.1429C13.563 10.1429 14.7143 8.99159 14.7143 7.57143C14.7143 6.15127 13.563 5 12.1428 5C10.7227 5 9.57141 6.15127 9.57141 7.57143C9.57141 8.99159 10.7227 10.1429 12.1428 10.1429Z"
        fill="white"
      />
      <path
        d="M17.2857 14.1067C17.2857 15.7042 17.2857 16.9996 12.1429 16.9996C7 16.9996 7 15.7042 7 14.1067C7 12.5092 9.30271 11.2139 12.1429 11.2139C14.983 11.2139 17.2857 12.5092 17.2857 14.1067Z"
        fill="white"
      />
    </svg>
  );
};

export default AvatarIcon;
