// SERVER
export const HOST_URL = "http://localhost:3000/";
export const BASE_URL = "https://admin.simplisell.co/api/v2/";
export const BASE_URL_AUTH = "https://admin.simplisell.co/api/v2/";
export const DEFAULT_WALL_MAP = "https://storage.simplisell.workers.dev/8373/6526ff32cf8c1_wallmap.png";
export const DEFAULT_FLOOR_MAP = "https://storage.simplisell.workers.dev/8377/652703cd1fe90_4f106d1b5cde1908c8f2ba2c074b3faf.jpg";

// DEV-LOCAL
// export const HOST_URL = "http://localhost:3000/";
// export const BASE_URL = "http://localhost:8001/";
// export const BASE_URL_AUTH = "http://localhost:8000/";
// export const DEFAULT_WALL_MAP = "5f07f740b5991623548246d7"
// export const DEFAULT_FLOOR_MAP = "5ef849c28188602a98c95524"

// API TOKEN-----------------------------------------------------------
// export const TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiMzI3MjFjMTQ1ZTQ2NzM0NWIwNTUyNjNmN2NmZGY2MTRhODFhYjM4ZDRlZjk5ZWY1YWM3YzEyMDFjYWIwMDVjMTc2OWFhMzA3OGQ1ZGMzZjkiLCJpYXQiOjE3MDkwNDA0MTcuOTcyMTcxLCJuYmYiOjE3MDkwNDA0MTcuOTcyMTczLCJleHAiOjE3NDA2NjI4MTcuOTQ2ODk2LCJzdWIiOiIyNDgiLCJzY29wZXMiOltdfQ.F34eu7lSppXg9w78frDajQnYMxHvcqs_Ads2sz8NmljHtZRiql-NwQ7zeDDFd80ecSUhEA52rrHU7dUvYj1Tghw2ikb6HwMubIn9F9JETApHNzp2lDt__c02zWI7LAb6aEuJksX8HGtQ79EyU9mqJWCH-rPG-afcjK2jyRn4Y0wRozDAAgmwzmW0eYt1qqZbCfw5SN5po1NizuSGE-Aoerr6ChRl63bsKJFIQuEeaX7FaPmrEMTSYrqG_XCk0SX7DOaHrhFbhRMqbbocfYg08ATs6mzm6EmGmklF4DPVoAFC2o3-ePICwGm7RwFyIxhMdB4qexosc2frX3qNjMpcNzXGEBXKCayKKN9Hry700WZu5WPKxA_6iJUiE9vNxzZOpU0XNsGDNIAEY1--cXSbGWVCyhsmDIdRxgZb6n1myqTlLT8ZHt03fKtv_WWwzrOfRdrthgFdOeuVRdkJAPo2xH1hNk5P3b9_UO08IAsoE7k2bwPPG8xzPyzHg9Xx0oqX2PdKz3ZxN3_6LCRATjWMxSpzMSAfh6PxG2lBW26gZEEHZULnlIJS5L8aBbim6DMdwDeku5Y1Ok_x0CSOsXBmKcgfkJzRqSIklbOfv_mLbDFQ_A4I67kYEJdqwo-mtpcHBON0eXyauV681ZbVOcfrAChxIqQwM9zjp0rG0MxN5-k";

//STORE PUBLIC LINK-----------------------------------------------------------
// export const S_LINK = "f5d7fe51f6724064b640b6264fdbdda0";

//PLANNAR API TOKEN-----------------------------------------------------------
export const TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiM2JlNTU2MDUxZjY2MmFhNmMwZmUyNDY2MDZlY2Y3NWE1ZWQ3MjYxZjU0ODE1ZjgxZmFhYjE1YTc0MWIyMjYzNmJjNmZmOGJiM2EyNjcwOGIiLCJpYXQiOjE3MDk3MDkxODEuMjE5OTU2LCJuYmYiOjE3MDk3MDkxODEuMjE5OTU4LCJleHAiOjE3NDEyNDUxODEuMTk3NDQxLCJzdWIiOiIyNDkiLCJzY29wZXMiOltdfQ.lYgeN7w-kjm2KA6N3uhVXEtFFwdqh1_NQt8a2aDKPgjaDMcvaTito_88d4eviGcgx8byLhVTFsezpcAvXr7iX5EkOR3N3sCuaCZ_vLSGjxiBSyuWiYriozPSDBwaG_UyLMIFHn92Jp5oSrbMuWyIjf46JtZxonujmpO6e9laOos6zTpf_skVqk1Ruhio4FQ-4wgm7WCjDWqL48FbLWfUCeekt9jsCwugjg_TucrvT3VTR5MQJapHQ0H87DeCXr63xoO8KwiJXGnbwbu5im_mXv2-NYcrSjZojJVLVSH6u8Y6l5_q2aEfCqrjvOqcH4QdwsiCsyo1kXFXrtiocE8Uo9BsgPfsPlHYYwoVX1kuZfwD9ByUMvm2PlKTuGAs6b1Xr8ddDSmGxnhtNhxJS8ut2fr2MNu2RrC_qjEPNxIbBoB86P-L8LNyHEscS8Uk6G2b2c1gIQu-AOW2XhyNTKuBE-IjGz9ef3bigCTehIxje6LwXT57xVbO4HtJiF9mb_lwEbUi8v0XuWBJYrLmQdnDWdnN5Fj2mMwXDBX0r4vAxfeuEh4KfjZep5qBKQ_f0pOw3k4w8ZVseCfTCWdjhzSxnIzou1QGukzOHEnPDAMQKsyLgfLPjSXkKdV9x0hogUdpS7TlpXy_P3XxT-xtY9p-lotADQWtSv0MumZAzUI0zCQ"

//PLANNAR STORE PUBLIC LINK-----------------------------------------------------------
export const S_LINK = "28e374b5c6e44c86b3a71ef8eaab7c5f";

//CRYPTOJS KEY-----------------------------------------------------------
export const CRYPTOJS_KEY = "CryptKey1293";

//PABBLY WEBHOOK-----------------------------------------------------------
export const PABBLY_USER_REGISTER = "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY4MDYzZjA0MzM1MjZhNTUzNTUxMzci_pc";

//MIXPANEL-----------------------------------------------------------
export const MIXPANEL_PROJECT_TOKEN = "6744c65e5be2d018427114c4bc3aa328";

// ENDPOINT-----------------------------------------------------------
export const MIRRAR_PAGE_URL = "https://mirrar.in/";
export const CATALOGUE_ENDPOINT = "user/catalogues";
export const PRODUCT_ENDPOINT = "user/products";
export const PRODUCT_CATEGORIES_ENDPOINT = "user/product-categories";
export const CATEGORY_PRODUCT_ENDPOINT = "user/category-products";
export const PLANNAR_ENDPOINT = "customer/plannar";
export const PLANNAR_SHARE_ENDPOINT = "customer/view";
export const CUSTOMER_LOGIN = "customer/login";
export const CUSTOMER_REGISTER = "customer/register";

// COMMON-----------------------------------------------------------
export const API = "api/";
export const VERSION = "v2/";
export const USER = "user/";
export const CATALOGUES = "catalogues";
export const PRODUCTS = "products";
export const PLANNAR = "plannar";

export const ASSETS = "assets/";
export const MODELS = "models/";
export const FLOOR_MODELS = "floorModel/"
export const TEXTURES = "textures/";
export const RETRIEVE = "retrieve/";
export const USERS = "users/";
export const RESOURCES = "resources/";
export const FIND = "find/";
export const VIEW_MODEL = "view/"

export const REGISTER = "register";
export const LOGIN = "login";
export const SIGNUP = "signup";
export const LOGOUT = "logout";
export const UPLOAD_ASSET = "uploadAsset";
export const UPDATE_ASSET_ENTRY = "updateAssetEntry";

export const SOFA_CATEGORY = "sofa";
export const CHAIR_CATEGORY = "chair";
export const BED_CATEGORY = "bed";
export const ARCH_CATEGORY = "architectural";
export const MISC_CATEGORY = "misc";
export const KITCHEN_CATEGORY = "kitchen";
export const RUG_CATEGORY = "rug";
export const TABLE_CATEGORY = "table";
export const CABINET_CATEGORY = "cabinet";
export const CURTAIN_CATEGORY = "curtain";
export const LIGHT_CATEGORY = "light";
export const BATH_CATEGORY = "bath";
export const FLOOR_CATEGORY = "floor";
export const FLOOR_WOOD_CATEGORY = "floorWood";
export const FLOOR_MARBLE_CATEGORY = "floorMarble";
export const FLOOR_TILE_CATEGORY = "floorTile";
export const WALL_CATEGORY = "wall";
export const WALL_SOLID_CATEGORY = "solid";
export const WALL_TILE_CATEGORY = "wallTile";
export const GET_FREE_RESOURCES = "getFreeResources";
export const GET_RESOURCES = "getResources";

export const REMOVE_MODEL = "removeModel";
export const RETRIEVE_MODEL = "retrieve";
export const UPDATE_MODEL = "update";
export const PROJECT = "project/";

export const WALL_HEIGHT = "304.8";
export const WALL_THICKNESS = "15";