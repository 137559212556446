import React from "react";

const MenuDots = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M7.99992 4.66667C7.6463 4.66667 7.30716 4.52619 7.05711 4.27614C6.80706 4.02609 6.66659 3.68696 6.66659 3.33333C6.66659 2.97971 6.80706 2.64057 7.05711 2.39052C7.30716 2.14048 7.6463 2 7.99992 2C8.35354 2 8.69268 2.14048 8.94273 2.39052C9.19278 2.64057 9.33325 2.97971 9.33325 3.33333C9.33325 3.68696 9.19278 4.02609 8.94273 4.27614C8.69268 4.52619 8.35354 4.66667 7.99992 4.66667ZM7.99992 9.33333C7.6463 9.33333 7.30716 9.19286 7.05711 8.94281C6.80706 8.69276 6.66658 8.35362 6.66658 8C6.66658 7.64638 6.80706 7.30724 7.05711 7.05719C7.30716 6.80714 7.6463 6.66667 7.99992 6.66667C8.35354 6.66667 8.69268 6.80714 8.94273 7.05719C9.19278 7.30724 9.33325 7.64638 9.33325 8C9.33325 8.35362 9.19278 8.69276 8.94273 8.94281C8.69268 9.19286 8.35354 9.33333 7.99992 9.33333ZM7.99992 14C7.6463 14 7.30716 13.8595 7.05711 13.6095C6.80706 13.3594 6.66658 13.0203 6.66658 12.6667C6.66658 12.313 6.80706 11.9739 7.05711 11.7239C7.30716 11.4738 7.6463 11.3333 7.99992 11.3333C8.35354 11.3333 8.69268 11.4738 8.94273 11.7239C9.19278 11.9739 9.33325 12.313 9.33325 12.6667C9.33325 13.0203 9.19278 13.3594 8.94273 13.6095C8.69268 13.8595 8.35354 14 7.99992 14Z"
        fill="black"
      />
    </svg>
  );
};

export default MenuDots;
