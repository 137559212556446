import React from "react";

const Logo = ({ width = 718, height = 180, className }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      width={width}
      height={height}
      viewBox="0 0 1500 549"
      className={className}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="859.2"
          y1="178.75"
          x2="748.88"
          y2="369.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#955cf7" />
          <stop offset=".5" stopColor="#4213ff" />
          <stop offset=".59" stopColor="#4216ff" />
          <stop offset=".68" stopColor="#4322ff" />
          <stop offset=".76" stopColor="#4436ff" />
          <stop offset=".85" stopColor="#4651ff" />
          <stop offset=".93" stopColor="#4974ff" />
          <stop offset="1" stopColor="#4c96ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="638.05"
          y1="351.47"
          x2="648.22"
          y2="417.79"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="664.44"
          y1="-2854.14"
          x2="705.71"
          y2="-3389.43"
          gradientTransform="translate(2117.05 2840.43) rotate(-49.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".09" stopColor="#955cf7" />
          <stop offset="1" stopColor="#2d007f" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="1058.7"
          y1="-2945.6"
          x2="604.84"
          y2="-2860.73"
          gradientTransform="translate(2117.05 2840.43) rotate(-49.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#764ff6" />
          <stop offset=".06" stopColor="#7c49eb" />
          <stop offset=".17" stopColor="#8e3ace" />
          <stop offset=".31" stopColor="#aa219f" />
          <stop offset=".42" stopColor="#c40c77" />
          <stop offset=".61" stopColor="#db4148" />
          <stop offset=".78" stopColor="#ec6925" />
          <stop offset=".87" stopColor="#f37918" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="949.73"
          y1="-3004.35"
          x2="856.32"
          y2="-3259.29"
          gradientTransform="translate(2117.05 2840.43) rotate(-49.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".09" stopColor="#955cf7" />
          <stop offset=".53" stopColor="#4213ff" />
          <stop offset="1" stopColor="#4c96ff" />
        </linearGradient>
      </defs>
      <g>
        <path
          className="cls-5"
          d="m923.22,352.5v56.12h-33.67c-23.99,0-42.7-5.87-56.12-17.61-13.42-11.74-20.12-30.89-20.12-57.47v-85.92h-26.32v-54.95s23.02.57,50.12-26.53c27.1-27.1,25.46-63.56,25.46-63.56l16.91.17v89.92h43.34v54.95h-43.34v86.69c0,6.45,1.55,11.1,4.64,13.93,3.1,2.84,8.25,4.26,15.48,4.26h23.61Zm-212.62-206.65c-6.67-6.39-10-14.36-10-23.89s3.33-17.49,10-23.89c6.67-6.39,15.04-9.6,25.11-9.6s18.44,3.2,25.11,9.6c6.67,6.4,10,14.36,10,23.89s-3.34,17.49-10,23.89c-6.67,6.4-15.04,9.6-25.11,9.6s-18.44-3.2-25.11-9.6Zm53.29,36.55v226.22h-57.17v-226.22h57.17Z"
        />
        <circle className="cls-1" cx="642.27" cy="378.99" r="29.63" />
      </g>
      <g>
        <polygon
          className="cls-4"
          points="100.78 422.24 101.9 125.41 346.14 218.14 340.04 520.78 100.78 422.24"
        />
        <polygon
          className="cls-6"
          points="340.04 520.78 564.56 415.71 566.01 118.09 346.14 218.14 340.04 520.78"
        />
        <polygon
          className="cls-3"
          points="101.9 125.41 326.84 23.22 566.01 118.09 346.14 218.14 101.9 125.41"
        />
      </g>
      <path
        className="cls-2"
        d="m213.83,349.15c3.43,10.54,5.17,21.77,5.21,33.66.06,17.15-2.72,29.57-8.31,37.23-5.55,7.61-13.26,9.28-23.08,5.08l-42.45-18.12-.93-196.88,41.09,12.7c9.56,2.95,17.13,10.05,22.66,21.29,5.57,11.32,8.39,25.21,8.45,41.61.04,12.11-1.41,21.63-4.36,28.55-2.94,6.91-6.85,11-11.73,12.31,5.53,4.55,10.01,12.07,13.43,22.56Zm-47.21-51.4l14.7,5.25c3.72,1.33,6.57.58,8.56-2.27,1.99-2.85,2.98-7.74,2.95-14.67-.03-6.93-1.06-12.57-3.08-16.9-2.02-4.32-4.89-7.1-8.61-8.33l-14.71-4.89.18,41.81Zm25.73,84.15c2.08-2.91,3.12-7.92,3.09-15.03s-1.15-13.12-3.36-17.99c-2.2-4.86-5.21-8.01-9.02-9.45l-16.28-6.15.19,43.99,16.54,6.68c3.81,1.54,6.76.86,8.84-2.03Z"
      />
      <path
        className="cls-2"
        d="m256.27,244.74l.38,125.77c.04,12.55,1.56,22.8,4.57,30.75,3.02,7.99,7.45,13.16,13.32,15.51,5.9,2.37,10.44.76,13.61-4.85,3.17-5.63,4.75-14.86,4.72-27.66l-.32-128.31,26.1,8.07.27,129.82c.04,19.37-1.98,34.93-6.05,46.63-4.05,11.65-9.48,19.39-16.27,23.24-6.74,3.82-14.2,3.97-22.36.47-8.09-3.47-15.28-9.77-21.59-18.9-6.26-9.06-11.19-20.78-14.82-35.15-3.61-14.32-5.44-30.69-5.51-49.17l-.42-123.77,24.37,7.54Z"
      />
      <g>
        <path
          className="cls-2"
          d="m400.82,417.02l38.15-20.11-.62,39.11-68.71,38.3,2.64-220.95,30.94-12.21-2.41,175.86Z"
        />
        <path
          className="cls-2"
          d="m525.35,205.32c7.82,5.01,13.69,13.99,17.66,26.91,3.94,12.81,5.68,28.72,5.26,47.79-.41,18.84-2.89,36.62-7.45,53.42-4.58,16.9-10.93,31.56-19.08,44.03-8.26,12.63-17.86,22.02-28.86,28.15l-41.14,22.93,3.45-207.91,41.5-16.38c11.19-4.42,20.72-4.03,28.65,1.05Zm-10.5,132.44c5.46-12.57,8.35-27.86,8.73-45.97.37-18.14-1.95-31.12-7.02-38.94-5.12-7.91-12.54-9.81-22.34-5.61l-12.5,5.36-2.15,118.33,12.44-6.51c9.75-5.11,17.34-14,22.84-26.66Z"
        />
      </g>
    </svg>
  );
};

export default Logo;
