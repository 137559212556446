import "../App.css";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { BASE_URL, PRODUCT_CATEGORIES_ENDPOINT, CATEGORY_PRODUCT_ENDPOINT,TOKEN } from "../Constants";
import axios from "axios";

class TextureGroutingContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      angle: 0,
      size: 2,
      colors: [], // Store colors fetched from API
      selectedColor: "b1b1b1", // Store the selected color
    };
  }

  componentDidMount() {
    this.fetchCatagories();
  }

  async fetchCatagories(page, allCategories = []) {
    await axios
      .get(BASE_URL + PRODUCT_CATEGORIES_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          page: page,
        },
      })
      .then((response) => {
        const newData = response.data.data;
        const categories = allCategories.concat(newData);

        if (page < response.data.last_page) {
          // If there are more pages, recursively fetch the next page
          return this.fetchCatagories(page + 1, categories);
        } else {
          // Filter out categories with names containing "-floor/wall", "- wall", or "- floor"
          const filteredCategories = categories.filter(category => 
            category.name.toLowerCase().includes('grout')
          );

          this.fetchProducts(filteredCategories[0].id);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        this.setState({
          loading: false
        });
      });
  }

  async fetchProducts (id, page=1, allProducts = []) {
    await axios
      .get(BASE_URL + CATEGORY_PRODUCT_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          category_id: id,
          page: page,
        },
      }).then((response) => {
        const data = response.data.data;
        const updateProducts = allProducts.concat(data);

        if (page < response.data.last_page) {
          // If there are more pages, recursively fetch the next page
          return this.fetchProducts(id, page + 1, updateProducts);
        } else {
          this.setState({
            colors: updateProducts.reverse()
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }

  handleAngleChange = (e) => {
    this.setState({ angle: e.target.value });
  };

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  handleColorChange = (e) => {
    this.setState({ selectedColor: e.target.value });
  };

  // resetState = () => {
  //   this.setState({
  //     angle: 0,
  //     size: 2,
  //     selectedColor: "", // Reset selected color
  //   });
  // };

  render() {
    const { angle, size, colors, selectedColor } = this.state;

    const isButtonDisabled = selectedColor === ""; // Disable button if no color selected

    return (
      <div id="texture-grouting-context-menu">
        <div className="texture-panel-heading">
          <div className="panel-heading">Adjust Size & Color (Optional)</div>
          <hr className="small-underline" />
          <div className="panel-body">
            <div className="form-horizontal">
              <div className="form-group">
                <label className="control-label">Angle (degrees)</label>
                <input
                  type="number"
                  className="form-control"
                  id="texture-angle"
                  value={angle}
                  onChange={this.handleAngleChange}
                ></input>
              </div>
              <div className="form-group">
                <label className="control-label">Size (mm)</label>
                <select
                  className="form-control"
                  id="texture-size"
                  value={size}
                  onChange={this.handleSizeChange}
                >
                  {[...Array(10).keys()].map((i) => (
                    <option key={i} value={i + 1}>
                      {i + 1} mm
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">Color</label>
                <select
                  className="form-control"
                  id="texture-color"
                  value={selectedColor}
                  onChange={this.handleColorChange}
                >
                  <option value="">Select Color</option>
                  {colors.map((color) => (
                    <option 
                    key={color.product.id} 
                    value={color.product.name} 
                    style={{ backgroundColor: color.product.name ? `#${color.product.name}` : "transparent", color: "#ffffff" }}>
                      {color.product.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="btn"
          size="sm"
          block
          id="apply-grout-texture"
          className="outline-button"
          disabled={isButtonDisabled}
        >
          <span className="text-centre">Apply Grout</span>
        </Button>
        {/* <Button
          id="reset-texture"
          onClick={this.resetState}
          className="d-none"
        ></Button> */}
      </div>
    );
  }
}

export default TextureGroutingContextMenu;
