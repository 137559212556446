import "../../../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseIcon from "../../SvgComponents/CloseIcon";

@inject("store")
@observer
class CloseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      isError: false,
      fileName: "",
      fileNameTemp: localStorage.getItem("projectName"),
      fileUri: "",
      stateBlob: {},
      saveFileList: [],
      showModal: false,
      copied: false,
      overwrite: "Save",
      id: "",
    };

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.store.setCloseModal(false);
  }
  

  render() {
    const { store } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showCloseModal}
        onHide={() => this.handleClose()}
        id="close-modal"
      >
        <div className="text-right mx-2 mt-2">
          <Button
            variant="none"
            className="save-close-btn"
            onClick={() => this.handleClose()}
          >
            <CloseIcon />
          </Button>
        </div>
        <Modal.Body className="pt-0">
          <div className="">
            <div className="save-file-space text-center">
              <div className="black-text fs-16 fw-700 my-3 ml-3">
                Do you want to save this project?
              </div>
            </div>

            <div className="text-center my-3">
              <Button
                variant="btn"
                className="modal-save-button mr-2"
                onClick={this.props.clickFunc}
              >
                Yes
              </Button>
              <Button
                variant="btn"
                className="modal-close-button"
                onClick={this.props.closeFunc}
              >
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CloseModal;
