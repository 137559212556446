import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { isMobile, isTablet } from "react-device-detect";
import Header from "./Header/Header.jsx";
import ProjectList from "./ProjectList.jsx";
import { withRouter } from "react-router-dom";

@inject("store")
@observer
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLandscape: this.isLandscape(),
      dashBoard: true,
      newProject: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isLoggedIn = this.props.store.getLoggedIn;
    if (!isLoggedIn){
      this.props.history.push("/");
    }
  }

  handleResize = () => {
    this.setState({
      isLandscape: this.isLandscape(),
    });
  };

  isLandscape() {
    return Math.abs(window.orientation) === 90;
  }

  render() {
    const { isLandscape } = this.state;

    if (!isLandscape && isMobile && !isTablet) {
      return (
        <div className="landscape">
          <p>
          We are yet to optimize the app for mobile usage, but you can give it a try by rotating your phone to landscape mode.
For better experience we suggest you to use it on your desktop or laptop.
          </p>
        </div>
      );
    }

    return (
      <div className="dashboard-container">
        <div className="header-container">
          <Header />
        </div>
        <div className="project-list-container pt-5">
          <ProjectList />
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
