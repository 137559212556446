import React, { Component } from "react";
import BluePrintPage from "./BlueprintPage.jsx";
import ComingSoonPage from "./ComingSoonPage.jsx";
import { inject, observer } from "mobx-react";
import { isMobile, isTablet } from "react-device-detect";

@inject("store")
@observer
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLandscape: this.isLandscape(),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      isLandscape: this.isLandscape(),
    });
  };

  isLandscape() {
    return Math.abs(window.orientation) === 90;
  }

  render() {
    const { store } = this.props;

    const { isLandscape } = this.state;

//     if (!isLandscape && isMobile && !isTablet) {
//       return (
//         <div className="landscape">
//           <p>We are yet to optimize the app for mobile usage, but you can give it a try by rotating your phone to landscape mode.
// For better experience we suggest you to use it on your desktop or laptop.</p>
//         </div>
//       );
//     }

    return (
      <div style={{ width: "100%", height: "100%" }}>
        {this.props.match && (
          <BluePrintPage
            viewKey={this.props.match.params.viewKey}
            addClickListener={store.getClickListener}
          />
        )}
        {!this.props.match && (
          <BluePrintPage
            viewKey=""
            addClickListener={store.getClickListener}
          />
        )}
        <ComingSoonPage />
      </div>
    );
  }
}

export default Homepage;
