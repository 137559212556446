import React from "react";

const DownArrow = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_31_2631)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70666 10.7063C8.51916 10.8935 8.265 10.9987 7.99999 10.9987C7.73499 10.9987 7.48083 10.8935 7.29333 10.7063L3.52133 6.9356C3.33382 6.748 3.22851 6.4936 3.22858 6.22836C3.22864 5.96313 3.33406 5.70878 3.52166 5.52127C3.70926 5.33376 3.96366 5.22845 4.2289 5.22852C4.49414 5.22858 4.74849 5.334 4.93599 5.5216L7.99999 8.5856L11.064 5.5216C11.2525 5.33935 11.5051 5.23844 11.7673 5.24059C12.0295 5.24274 12.2803 5.34779 12.4658 5.53312C12.6513 5.71844 12.7566 5.9692 12.759 6.2314C12.7614 6.4936 12.6607 6.74625 12.4787 6.93493L8.70733 10.7069L8.70666 10.7063Z"
          fill="#384350"
        />
      </g>
      <defs>
        <clipPath id="clip0_31_2631">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownArrow;
