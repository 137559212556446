import "../App.css";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";

class ColorCodeContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorCode: "",
    };
  }

  handleColorChange = (e) => {
    let colorValue = e.target.value;
    // Check if colorValue starts with '#' and prepend it if not
    if (!colorValue.startsWith('#')) {
      colorValue = '#' + colorValue;
    }
    this.setState({ colorCode: colorValue });
  };

  resetState = () => {
    this.setState({
      colorCode: "",
    });
  };

  render() {
    const { colorCode } = this.state;

    const isButtonDisabled = colorCode === "";
    
    return (
      <div className="pb-5" id="color-context-menu">
        <div className="texture-panel-heading">
          <div className="panel-heading">Color</div>
          <hr className="small-underline" />
          <div className="panel-body">
            <div className="form-horizontal">
              <div className="form-group">
                <label className="control-label">Enter Your Color Code</label>

                <input
                  type="text"
                  className="form-control"
                  id="color-code"
                  value={colorCode}
                  onChange={this.handleColorChange}
                  placeholder="#D4AC0D"
                ></input>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="btn"
          size="sm"
          block
          id="apply-color"
          className="outline-button"
          disabled={isButtonDisabled}
        >
          <span className="text-centre">Apply</span>
        </Button>
        <Button
          id="reset-color"
          onClick={this.resetState}
          className="d-none"
        ></Button>
      </div>
    );
  }
}

export default ColorCodeContextMenu;