import React from "react";

const DrawIcon = ({ width = 24, height = 24, className, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      className={className}
    >
      <path
        d="M21.5635 15.2828L24.7172 18.4365M11.0512 25.7951L10 30L14.2049 28.9488L26.3844 16.7693C26.7786 16.375 27 15.8403 27 15.2828C27 14.7253 26.7786 14.1906 26.3844 13.7964L26.2036 13.6156C25.8093 13.2214 25.2747 13 24.7172 13C24.1597 13 23.625 13.2214 23.2307 13.6156L11.0512 25.7951Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="3" y="6" width="3" height="23" fill={fill} />
    </svg>
  );
};

export default DrawIcon;
