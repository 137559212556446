import React, { Component } from "react";
import Dashboard from "./Components/Dashboard.jsx";
import Homepage from "./Components/Homepage.jsx";
import Login from "./Components/Login.jsx";
import Signup from "./Components/Signup.jsx";
import { inject, observer } from "mobx-react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { parseJwt } from "../src/Utils/Utils";

@inject("store")
@observer
class App extends Component {
  componentDidMount() {
    const accessToken = localStorage.getItem("accessToken");
    const user_name = localStorage.getItem("user_name");
    if (accessToken) {
      const loggedInUser = parseJwt(accessToken);
      const tokenExpiration = loggedInUser.exp * 1000;
      const currentTimestamp = new Date().getTime();
      const tokenExpirationTime = tokenExpiration - currentTimestamp;

      if (tokenExpirationTime <= 0) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user_name");
        localStorage.removeItem("projectName");
        this.props.store.setLoggedIn(false);
        this.props.store.setUsername("");
        this.props.store.setAccessToken("");
      } else {
        this.props.store.setLoggedIn(true);
        this.props.store.setUsername(user_name);
        this.props.store.setAccessToken(accessToken);
        setTimeout(() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user_name");
          localStorage.removeItem("projectName");
          this.props.store.setLoggedIn(false);
          this.props.store.setUsername("");
          this.props.store.setAccessToken("");
        }, tokenExpirationTime);
      }
    }

    window.scrollTo(0, 0);
  }

  render() {
    const isLoggedIn = this.props.store.getLoggedIn;
    const location = window.location;
    const shouldRedirect = location.pathname.includes('view');
    return (
      <Router>
        <Switch>
          <Route path="/about"></Route>
          <Route path="/project/:viewKey" component={Homepage} />
          <Route path="/signup">
            {isLoggedIn && !shouldRedirect ? <Redirect to="/dashboard" /> : <Signup />}
          </Route>
          <Route path="/login">
            {isLoggedIn && !shouldRedirect ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route path="/dashboard">
            {isLoggedIn ? <Dashboard /> : <Redirect to="/login" />}
          </Route>
          <Route path="/">
            {!isLoggedIn || (isLoggedIn && shouldRedirect) ? <Homepage /> : <Redirect to="/dashboard" />}
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;