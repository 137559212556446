import "../App.css";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { WALL_HEIGHT } from "../Constants";

class WallContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: WALL_HEIGHT,
    };
  }

  handleHeightChange = (e) => {
    const newValue = e.target.value;
    this.setState({ height: newValue });
  };

  render() {
    const { width, height } = this.state;

    const isButtonDisabled =  height === "" || height < 8;
    
    return (
      <div id="wall-context-menu">
        <div className="texture-panel-heading">
          <div className="panel-heading">Adjust Wall Height   </div>
          <hr className="small-underline" />
          <div className="panel-body">
            <div className="form-horizontal">
              <div className="form-group">
                <label className="control-label">Height (cm)</label>

                <input
                  type="number"
                  className="form-control"
                  id="wall-height"
                  value={height}
                  min="8"
                  onChange={this.handleHeightChange}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="btn"
          size="sm"
          block
          id="apply-wall-height"
          className="outline-button"
          disabled={isButtonDisabled}
          onClick={this.handleSubmit}
        >
          <span className="text-centre">Apply</span>
        </Button>
      </div>
    );
  }
}

export default WallContextMenu;