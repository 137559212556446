import React from "react";

const PencilIcon = ({ width = 19, height = 19, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      className={className}
    >
      <path
        d="M12.5635 3.28282L15.7172 6.4365M2.05123 13.7951L1 18L5.20491 16.9488L17.3844 4.76925C17.7786 4.37498 18 3.84031 18 3.28282C18 2.72532 17.7786 2.19065 17.3844 1.79638L17.2036 1.61557C16.8093 1.22142 16.2747 1 15.7172 1C15.1597 1 14.625 1.22142 14.2307 1.61557L2.05123 13.7951Z"
        stroke="#0B2342"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;
