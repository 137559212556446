import "../../App.css";
import React, { Component } from "react";
import { Card, Form, FormControl, Button } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import $ from "jquery";
import { FaSearch } from "react-icons/fa";
import MixpanelService from "../../mixpanel/mixpanelService";

@inject("store")
@observer
class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      itemsToShow: 10,
      loading: false,
    };
  }

  componentDidMount() {
    $("#add-items")
      .find(".add-item")
      .hover(function (e) {
        $(".item-card-title").removeClass("active-title");
        $(this).find(".item-card-title").addClass("active-title");
      });
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (!this.props.store.getHasViewKey) {
      if (this.props.products !== prevProps.products) {
        this.props.store.setClickListener(true);
        this.setState({
          itemsToShow: 10,
          loading: false,
          searchQuery: ""
        });
      }
    }
  }

  handleSearch = (e) => {
    this.setState({
      searchQuery: e.target.value,
    });
  };

  loadMore = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState((prevState) => ({
        itemsToShow: prevState.itemsToShow + 10,
        loading: false,
      }));
    }, 1000);
  };

  handleCardClick = (item) => {
    const { store, categoryName } = this.props;
    var meta_data = JSON.parse(item.product.meta_data);
    var modelUrl = item.arobject[0].object_3d.url;
    var modelType;
    if (meta_data && meta_data["item_type"]) {
      modelType = meta_data["item_type"];
    } else {
      modelType = 1;
    }

    MixpanelService.trackEvent('3D Product', {
      'Name': item.product.name,
      'Category': categoryName,
      'Sku': item.product.sku,
    });

    var modelName = item.product.name;

    var data = {
      itemName: modelName,
      modelUrl: modelUrl,
      itemType: modelType,
      resizable: true,
    };

    this.props.setSelectedCardData(data);

    store.setClickListener(true);
  };

  render() {
    const { products } = this.props;
    const { searchQuery, itemsToShow } = this.state;

    const filteredProducts = searchQuery
      ? products.filter((item) =>
          item.product.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : products;

    const productsToDisplay = filteredProducts.slice(0, itemsToShow);

    const showLoadMoreButton =
      itemsToShow < filteredProducts.length &&
      filteredProducts.some(
        (item) =>
          item.arobject &&
          item.arobject.length > 0 &&
          item.arobject[0].object_3d
      );

    return (
      <div className="productList-card pt-0">
        <Form.Group>
          <div className="m-2 ps-r">
            <FaSearch className="ps-a search-icon" />
            <FormControl
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={this.handleSearch}
              className="search-form-control"
            />
          </div>
        </Form.Group>

        {productsToDisplay.length > 0 ? (
          productsToDisplay.map(
            (item, iterator) =>
              item.arobject &&
              item.arobject.length > 0 &&
              item.arobject[0].object_3d && (
                <Card
                  onClick={() => {
                    this.handleCardClick(item);
                  }}
                  key={iterator}
                  className="text-white item-card add-item"
                >
                  <Card.Img
                    src={item.product.photo[0].thumbnailFullUrl}
                    className="item-card-img"
                  />
                    <Card.Body className="p-0">
                      <Card.Title className="primary-text mt-2 mb-1 ml-1 product-title">{item.product.name}</Card.Title>
                    </Card.Body>
                  {/* <Card.ImgOverlay className="item-card-body">
                    {item.product.name && (
                      <Card.Body className="item-card-title-outer">
                        <div className="item-card-title">
                          {item.product.name}
                        </div>
                      </Card.Body>
                    )}
                  </Card.ImgOverlay> */}
                </Card>
              )
          )
        ) : (
          <div className="my-3 text-center">No products found.</div>
        )}

        {this.state.loading ? (
          <div className="loading-modal center">
            <img src="/spinner.gif" />
            <span>LOADING</span>
          </div>
        ) : (
          showLoadMoreButton && (
            <div className="text-center pb-3">
              <Button
                variant="primary"
                onClick={this.loadMore}
                className="load-more-btn mt-2"
              >
                Load More
              </Button>
            </div>
          )
        )}
      </div>
    );
  }
}

export default ItemList;
