import React from "react";

const SignupIcon = ({ width = 20, height = 20, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 139 139"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6941 0.445135C34.1808 5.10774 22.4084 42.0987 44.6908 63.3005C47.7162 66.1794 47.7265 66.1265 43.8535 67.6622C22.8578 75.9878 7.41047 93.7469 1.6414 116.192C-1.02854 126.579 3.46968 135.017 13.0996 137.686C16.5016 138.629 84.4351 138.55 85.9214 137.602C89.3622 135.406 89.9145 131.592 87.1647 129.013C85.3931 127.352 87.64 127.447 50.1581 127.441C12.0787 127.434 14.8367 127.567 13.0475 125.659C11.29 123.784 11.1321 122.177 12.285 117.886C21.5802 83.2955 59.2441 64.2494 91.7871 77.6833C98.4394 80.4293 101.541 79.88 102.687 75.7532C103.715 72.05 102.159 70.2403 95.7011 67.6279C91.8578 66.0731 91.8748 66.1635 94.8838 63.3005C120.562 38.8676 99.5496 -4.88097 64.6941 0.445135ZM76.0352 11.6054C95.9255 16.8313 102.392 41.6295 87.4962 55.5542C70.9202 71.0491 44.1242 59.5349 44.044 36.8833C43.9841 19.9073 59.8669 7.35739 76.0352 11.6054ZM111.222 87.4682C108.134 88.898 107.848 89.879 107.848 99.0409C107.848 108.264 108.919 107.2 99.6287 107.208C92.8352 107.213 91.9461 107.265 90.9773 107.713C87.3507 109.392 86.5538 113.772 89.3911 116.433C90.974 117.917 91.5136 117.999 99.6876 117.999C108.909 117.999 107.848 116.938 107.848 126.16C107.848 135.243 107.984 135.754 110.88 137.602C113.366 139.188 116.936 137.765 118.228 134.672C118.571 133.851 118.646 132.33 118.646 126.16C118.646 116.938 117.585 117.999 126.806 117.999C134.845 117.999 135.517 117.904 136.962 116.569C139.54 114.187 139.54 111.014 136.962 108.632C135.517 107.296 134.845 107.202 126.806 107.202C117.584 107.202 118.647 108.272 118.64 98.982C118.634 92.1886 118.583 91.2994 118.134 90.3306C116.836 87.526 113.807 86.2716 111.222 87.4682Z"
        fill="white"
      />
    </svg>
  );
};

export default SignupIcon;
