import React from "react";

const OpenIcon = ({ width = 16, height = 16, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M6.66675 2.66663H4.00008C3.64646 2.66663 3.30732 2.8071 3.05727 3.05715C2.80722 3.3072 2.66675 3.64634 2.66675 3.99996V12C2.66675 12.3536 2.80722 12.6927 3.05727 12.9428C3.30732 13.1928 3.64646 13.3333 4.00008 13.3333H12.0001C12.3537 13.3333 12.6928 13.1928 12.9429 12.9428C13.1929 12.6927 13.3334 12.3536 13.3334 12V9.33329M8.00008 7.99996L13.3334 2.66663M13.3334 2.66663V5.99996M13.3334 2.66663H10.0001"
        stroke="#0B2342"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OpenIcon;
